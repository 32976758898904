import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

import { ReactComponent as CheckIcon } from 'assets/icons/checkIcon.svg';
import { ReactComponent as CheckIconDisabled } from 'assets/icons/checkIconDisabled.svg';
import { ReactComponent as CheckIconGreen } from 'assets/icons/checkIconGreen.svg';

import './style.scss';

type SpanProps = {
  size: 'small' | 'medium';
};

export const Icon = styled('span')<SpanProps>(props => {
  const sizeIcon = props.size === 'medium' ? '24px' : '16px';

  return {
    padding: '0px',
    background: '#FEFFFF',
    border: '1.5px solid #C6C6C6',
    borderRadius: '5px',
    height: sizeIcon,
    width: sizeIcon,
    'input:disabled ~ &': {
      background: '#FEFFFF',
      border: '1.5px solid #DFDFE1',
    },
  };
});

interface ICheckbox extends CheckboxProps {
  disabled: boolean;
}

const CheckedIcon = ({ disabled, checkedIcon }: ICheckbox) =>
  disabled ? <CheckIconDisabled /> : checkedIcon ? <CheckIconGreen /> : <CheckIcon />;

const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon disabled={props.disabled} checkedIcon={props.checkedIcon} />}
      icon={<Icon size={props.size} />}
      {...props}
    />
  );
};

export default CustomCheckbox;
