const formattedPhone = (phoneNumber: string, formatStyle = 1) => {
  if (phoneNumber) {
    switch (formatStyle) {
      case 1: {
        const phoneNumberStr = `${phoneNumber}`;
        const result = [
          phoneNumberStr.slice(0, 3),
          phoneNumberStr.slice(3, 6),
          phoneNumberStr.slice(6, 8),
          phoneNumberStr.slice(8, 10),
        ];
        return result.join(' ');
      }
      case 2: {
        const phoneNumberStr = `${phoneNumber}`;
        const result = [
          `(${phoneNumberStr.slice(0, 3)}) `,
          `${phoneNumberStr.slice(3, 6)}-`,
          `${phoneNumberStr.slice(6, 8)}-`,
          phoneNumberStr.slice(8, 10),
        ];
        return result.join('');
      }
      default: {
        return phoneNumber;
      }
    }
  } else {
    return '';
  }
};

export default formattedPhone;
