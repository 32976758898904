import { Meta, Status } from 'store/types';

import { Brand } from 'store/dashboard/types';

interface Image {
  id: number;
  path: string;
  name: string;
  thumbnailPath: string | null;
}

interface FiltrationBrands {
  data: Array<Brand>;
  meta: Meta;
  status: Status;
}

export interface SearchProductsInterface {
  keyword: string;
  page?: number;
}

export interface ProductInList {
  id: number;
  hasCashback: boolean;
  categoryId: number;
  discount: number;
  brandId: number;
  name: string;
  description: string;
  price: number;
  stock: number;
  cashback: number;
  info: null | string;
  itemCode: string | number;
  ownerId: number;
  image: Image;
  brandName?: string;
  shopInfo: { id: number; name: string };
  ratingSum: number;
  ratingCount: number;
  wishlistProductId: null | number;
  categoryName: any;
}

type ActiveTag = {
  id: number;
  name: string;
  colorCode: string;
  createdAt: string;
  updatedAt: string;
};

interface ActiveTags {
  data: Array<ActiveTag>;
  meta: Meta;
  status: Status;
}

export interface Label {
  id: number;
  name: string;
  value: string;
  type: string;
}

export interface ProductListState {
  data: Array<ProductInList>;
  meta: Meta;
  additionalData?: any;
  minPrice: number;
  maxPrice: number;
  activeTags: ActiveTags;
  getProductsErrorCode: number | null;
  status: Status;
  filters: {
    data: Array<any>;
    meta: Meta;
    getFiltersLoading: boolean;
  };
  formattedFilters: [];
  filtrationBrands: FiltrationBrands;
  resetedRangeField: {
    values?: number[];
    field: string;
  };
  productSuggestions: Array<any>;
  categoryPageSearchBy: SearchBy;
  filteringDataObject: any;
  labels: Label[];
  savedFilteringDataObjectForLater: any;
  savedLabelsForLater: [];
  storageDeliveryDurations: (number | string)[];
  partnerDeliveryData: (number | string)[];
  courierDeliveryData: (number | string)[];
  storageAndPartnerDurationsMerged: (number | string)[];
}

export interface ProductsSearch {
  search: string;
  page: number;
  filteringUrl?: string;
  limit?: number;
  elasticEnabled?: boolean;
}

export interface CategoriesParamsInterface {
  keyword?: string;
  categoryId?: number | string;
  bannerToken?: string;
  searchBy?: string;
  page: number;
  elasticEnabled: boolean;
  outOfCheck?: boolean;
  limit: number;
  isActual?: number;
  postalCode?: string;
  enableDurationPostalCode?: boolean;
  enableFiltrationV2?: boolean;
  filteringObject?: any;
}

export interface ActiveTagsRequestParams {
  page: number | string;
  keyword?: string;
  filteringObject?: any;
  limit?: number;
  enableFiltrationV2?: boolean;
  tagId?: string;
  tagIds?: string;
  brandId?: string;
  brandIds?: string;
  showNotExistingProducts?: boolean;
  brandKeyword?: string;
  order?: string;
  bannerToken?: string;
  categoryId?: string;
  categoryIds?: string;
  isActual?: number;
  postalCode: string;
  enableDurationPostalCode: boolean;
  deliveryDuration?: any;
  courierDeliveryDuration?: string;
  withoutPostalCode?: boolean;
}

export interface CategoryFilter {
  id: number;
  categoryId?: number;
  bannerPageId?: number;
  categoryName: string;
  filters: any;
  name: string;
  displayName: string;
  type: string;
}

export enum SearchBy {
  Auto = 'auto',
  CategoryId = 'categoryId',
  All = 'all',
}
