import { lazy } from 'react';
import ROUTES from 'constants/routes';

export const authRoutes = [
  {
    path: ROUTES.DASHBOARD,
    component: lazy(() => import('pages/Dashboard/')),
    isPrivate: false,
  },
  {
    path: ROUTES.SELLER_SIGN_UP,
    component: lazy(() => import('pages/Auth/Registration/steps/')),
    isPrivate: false,
  },
  {
    path: ROUTES.ACCOUNT,
    component: lazy(() => import('pages/Account/')),
    isPrivate: true,
  },
  {
    path: ROUTES.REVIEWS,
    component: lazy(() => import('pages/Account')),
    isPrivate: true,
  },
  {
    path: ROUTES.ORDERS_ITEM,
    component: lazy(() => import('pages/Account/')),
    isPrivate: true,
  },
  {
    path: ROUTES.BRANDS,
    component: lazy(() => import('pages/Brands/')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND,
    component: lazy(() => import('pages/Brand/')),
    isPrivate: false,
  },
  {
    path: ROUTES.SEARCH,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCT_BY_CATEGORIES_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND_CATEGORIES_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND_ACTUAL_OFFERS_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },

  {
    path: ROUTES.BRAND_RANDOM_OFFERS_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCT,
    component: lazy(() => import('pages/Product/')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCT_REVIEWS,
    component: lazy(() => import('pages/ProductReviews')),
    isPrivate: false,
  },
  {
    path: ROUTES.CART,
    component: lazy(() => import('pages/ShoppingCart')),
    isPrivate: false,
  },
  {
    path: ROUTES.MOBILECATALOG,
    component: lazy(() => import('sections/MobileCatalog/MobileCatalog')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCTS,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.WHOLESALER_PURCHASE,
    component: lazy(() => import('pages/Purchase')),
    isPrivate: true,
  },
  {
    path: ROUTES.FOOTER,
    component: lazy(() => import('pages/FooterList')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCTS_CATEGORIES,
    component: lazy(() => import('pages/Category/Category')),
    isPrivate: false,
  },
  {
    path: ROUTES.DYNAMIC_PAGE,
    component: lazy(() => import('pages/FooterList/FooterPages/DynamicPages/singlePage')),
    isPrivate: false,
  },
  {
    path: ROUTES.DYNAMIC_PAGES,
    component: lazy(() => import('pages/FooterList/FooterPages/DynamicPages')),
    isPrivate: false,
  },
  {
    path: ROUTES.MAX_MONEY_INFO,
    component: lazy(() => import('pages/MaxMoneyInfo')),
    isPrivate: false,
  },
  {
    path: ROUTES.APRIL_PAGE_INFO,
    component: lazy(() => import('pages/AprilpageInfo')),
    isPrivate: false,
  },
  {
    path: ROUTES.SBERBANK,
    component: lazy(() => import('pages/Sberbank')),
    isPrivate: false,
  },
  {
    path: ROUTES.REQUISITES,
    component: lazy(() => import('pages/FooterList/FooterPages/Requesites')),
    isPrivate: false,
  },
  {
    path: ROUTES.INSTALLMENT_INFO,
    component: lazy(() => import('pages/FooterList/FooterPages/InstallmentInfo')),
    isPrivate: false,
  },
  {
    path: ROUTES.SELLING_RULES_INFO,
    component: lazy(() => import('pages/FooterList/FooterPages/InfoSellingRules')),
    isPrivate: false,
  },
  {
    path: ROUTES.FRANCHISE_INFO,
    component: lazy(() => import('pages/FooterList/FooterPages/InfoFranchise')),
    isPrivate: false,
  },
  {
    path: ROUTES.NOT_FOUND,
    component: lazy(() => import('pages/NotFound')),
    isPrivate: false,
  },
  {
    path: ROUTES.SECURITY,
    component: lazy(() => import('pages/FooterList/FooterPages/SecurityPage')),
    isPrivate: false,
  },
];
