import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  errorMessages,
  NUMBER_REGEX,
  RUSSIAN_REGEX_ADDRESS,
  BANK_NAME_REGEX,
  RUSSIAN_ADDRESS_WITH_NUMBER,
  NUMBER_REGEX_SPACES,
} from './constants';

import getPhoneNumberFromFormat from 'helpers/getPhoneNumberFromFormat';

const {
  ERROR_REQUIRED_MESSAGE,
  ERROR_NUMBER_MESSAGE,
  ERROR_NOT_RUSSIAN,
  ERROR_INVALID_EMAIL,
  ERRROR_INVALID_BANK_NAME,
  ERRROR_INVALID_ADDRESS,
  ERROR_NUMBER_MESSAGE_STARTS_WITH_0,
} = errorMessages;

export const loginSchema = yupResolver(
  yup.object({
    phoneNumber: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('no-leading-zero', ERROR_NUMBER_MESSAGE_STARTS_WITH_0, value => {
        return value && !value.startsWith('0');
      })
      .test('len', 'Должно быть 10 символов', val => val.toString().length === 21),
  })
);

export const registrationSchema = yupResolver(
  yup.object({
    phoneNumber: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('no-leading-zero', ERROR_NUMBER_MESSAGE_STARTS_WITH_0, value => {
        return value && !value.startsWith('0');
      })
      .test('len', 'Должно быть 10 символов', val => val.toString().length === 21),
  })
);

export const userPersonalInfoData = yupResolver(
  yup.object({
    fullName: yup.string(),
    email: yup.string().email(ERROR_INVALID_EMAIL),
  })
);

export const userLegalInfoSchema = yupResolver(
  yup.object({
    tin: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 10 символов', val => val.toString().length === 10),
    legalAddress: yup
      .string()
      .matches(RUSSIAN_ADDRESS_WITH_NUMBER, ERROR_NOT_RUSSIAN)
      .test('len', ERRROR_INVALID_ADDRESS, val => {
        if (!NUMBER_REGEX_SPACES.test(val)) {
          return true;
        } else {
          return false;
        }
      })
      .required(ERROR_REQUIRED_MESSAGE),
    postalAddress: yup.string().required(ERROR_REQUIRED_MESSAGE),
    paymentAccount: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
    organizationForm: yup.string().required(ERROR_REQUIRED_MESSAGE),
    organizationName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    correspondentAccount: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
    bicCode: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 9 символов', val => val.toString().length === 9),
    bankName: yup
      .string()
      .matches(BANK_NAME_REGEX, ERRROR_INVALID_BANK_NAME)
      .required(ERROR_REQUIRED_MESSAGE),
    trr: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 9 символов', val => val.toString().length === 9),
    psrn: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 13 символов', val => val.toString().length === 13),
  })
);

const sellerSchema = yup.object({
  tin: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test(
      'len',
      '10 или 12 символов',
      val => val.toString().length === 10 || val.toString().length === 12
    ),
  trr: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 9 символов', val => val.toString().length === 9),
  psrn: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 13 символов', val => val.toString().length === 13),
  email: yup.string().email(ERROR_INVALID_EMAIL).required(ERROR_REQUIRED_MESSAGE),
  bicCode: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 9 символов', val => val.toString().length === 9),
  bankName: yup.string().required(ERROR_REQUIRED_MESSAGE),
  fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
  phoneNumber: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 10 символов', val => val.toString().length === 10),
  legalAddress: yup.string().required(ERROR_REQUIRED_MESSAGE),
  postalAddress: yup.string().required(ERROR_REQUIRED_MESSAGE),
  paymentAccount: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
  organizationForm: yup.string().required(ERROR_REQUIRED_MESSAGE),
  organizationName: yup.string().required(ERROR_REQUIRED_MESSAGE),
  correspondentAccount: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
});

const sellerShopSchema = yup.object({
  shopName: yup.string().required(ERROR_REQUIRED_MESSAGE),
  orderPhio: yup.string().required(ERROR_REQUIRED_MESSAGE),
  managerPhoneNumber: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    .test('len', 'Должно быть 10 символов', val => val.toString().length === 10),
  orderEmail: yup.string().email(ERROR_INVALID_EMAIL).required(ERROR_REQUIRED_MESSAGE),
});

export const registrantSellerSchema = yupResolver(sellerSchema);
export const registrantSellerShopSchema = yupResolver(sellerSchema.concat(sellerShopSchema));

export const userCardSchema = yupResolver(
  yup.object({
    cardNumber: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)

      .test('len', 'Неверный номер карты', (val: string) => {
        const valueLength = val.toString()?.replace(/\s/g, '').length;
        return valueLength === 15 || valueLength === 16;
      }),
    cardHolderName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    expirationDate: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .test('test-credit-card-expiration-date', 'Неверный срок действия', expirationDate => {
        if (!expirationDate) {
          return false;
        }
        const today = new Date();
        const monthToday = today.getMonth() + 1;
        const yearToday = today.getFullYear().toString().substr(-2);

        const [expMonth, expYear] = expirationDate.split('/');

        if (Number(expYear) < Number(yearToday)) {
          return false;
        } else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
          return false;
        }
        return true;
      }),
    // cvv: yup
    //   .string()
    //   .required(ERROR_REQUIRED_MESSAGE)
    //   .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
    //   .test('len', 'Должно быть 3 или 4 символа', val => {
    //     const valueLength = val.toString().length;
    //     return valueLength === 3 || valueLength === 4;
    //   }),
  })
);

export const addressSchema = (enableDurationPostalCode: boolean) =>
  yupResolver(
    yup.object().shape(
      {
        street: yup
          .string()
          .nullable()
          .notRequired()
          .when('street', {
            is: value => !!value?.length,
            then: rule => rule.matches(RUSSIAN_REGEX_ADDRESS, ERROR_NOT_RUSSIAN),
          }),
        building: yup.string(),
        apartment: yup.string(),
        city: yup.string().nullable(),
        address: yup.string().required(ERROR_REQUIRED_MESSAGE),
        zipCode: enableDurationPostalCode
          ? yup.string().required(ERROR_REQUIRED_MESSAGE)
          : yup.string(),
      },
      [['street', 'street']]
    )
  );

export const receiverSchema = yupResolver(
  yup.object({
    fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    email: yup.string().email(ERROR_INVALID_EMAIL).required(ERROR_REQUIRED_MESSAGE),
    phoneNumber: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
      .test('len', 'Должно быть 10 символов', val => val.toString().length === 21),
  })
);

export const buyerRequestSchema = yupResolver(
  yup.object({
    fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    email: yup
      .string()
      .email('Недействительный адрес электронной почты')
      .required(ERROR_REQUIRED_MESSAGE),
    theme: yup.string().required(ERROR_REQUIRED_MESSAGE),
    message: yup.string().required(ERROR_REQUIRED_MESSAGE),
  })
);

export const sellerUploadCatatlogSchema = yupResolver(
  yup.object({
    catalogUrl: yup.string().url('Неверный линк').required(ERROR_REQUIRED_MESSAGE),
  })
);

export const withdrawalsSchema = (balance: number) =>
  yupResolver(
    yup.object({
      tin: yup
        .string()
        .required(ERROR_REQUIRED_MESSAGE)
        .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
        .test(
          'len',
          'От 10 до 12 символов',
          val => val.toString().length === 10 || val.toString().length === 12
        ),
      bicCode: yup
        .string()
        .required(ERROR_REQUIRED_MESSAGE)
        .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
        .test('len', 'Должно быть 9 символов', val => val.toString().length === 9),
      fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
      paymentAccount: yup
        .string()
        .required(ERROR_REQUIRED_MESSAGE)
        .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
        .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
      correspondentAccount: yup
        .string()
        .required(ERROR_REQUIRED_MESSAGE)
        .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
        .test('len', 'Должно быть 20 символов', val => val.toString().length === 20),
      paymentPurpose: yup.string().required(ERROR_REQUIRED_MESSAGE),
      amount: yup
        .string()
        .required(ERROR_REQUIRED_MESSAGE)
        .matches(NUMBER_REGEX, ERROR_NUMBER_MESSAGE)
        .test('sum', `Не должен быть больше ${balance} руб.`, val => +val <= balance),
      companyName: yup.string().required(ERROR_REQUIRED_MESSAGE),
      taxSystem: yup.string().required(ERROR_REQUIRED_MESSAGE),
    })
  );

export const sellerEditProfileResolver = yupResolver(
  yup.object({
    fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    email: yup.string().required(ERROR_REQUIRED_MESSAGE).email(ERROR_INVALID_EMAIL),
    address: yup
      .string()
      .required(ERROR_REQUIRED_MESSAGE)
      .nullable()
      .matches(RUSSIAN_REGEX_ADDRESS, ERROR_NOT_RUSSIAN),
  })
);

export const createReviewSchema = yupResolver(
  yup.object({
    rating: yup.number().positive('Выберите оценку').required('Выберите оценку'),
    message: yup.string().required('Заполните поле'),
  })
);

export const phoneNumberResolver = yupResolver(
  yup.object({
    phoneNumber: yup
      .string()
      .required(`${ERROR_REQUIRED_MESSAGE} или неверный формат `)
      .test('no-leading-zero', ERROR_NUMBER_MESSAGE_STARTS_WITH_0, value => {
        return value && !value.replace(/[()]/g, '').startsWith('0');
      })
      .test(
        'format',
        `${ERROR_REQUIRED_MESSAGE} или неверный формат `,
        val => val.indexOf('_') == -1
      ),
  })
);

export const userPartnership = yupResolver(
  yup.object({
    fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
    email: yup.string().required(ERROR_REQUIRED_MESSAGE).email(ERROR_INVALID_EMAIL),
    address: yup.string().required(ERROR_REQUIRED_MESSAGE),
    phoneNumber: yup.string().required(ERROR_REQUIRED_MESSAGE),
  })
);

const franchiseInfoFormSchema_1 = yup.object({
  email: yup.string().required(ERROR_REQUIRED_MESSAGE).email(ERROR_INVALID_EMAIL),
});

const franchiseInfoFormSchema_2 = yup.object({
  fullName: yup.string().required(ERROR_REQUIRED_MESSAGE),
  phoneNumber: yup
    .string()
    .required(ERROR_REQUIRED_MESSAGE)
    .test('no-leading-zero', ERROR_NUMBER_MESSAGE_STARTS_WITH_0, value => {
      return value && !getPhoneNumberFromFormat(value).startsWith('0');
    })
    .test(
      'len',
      'Должно быть 10 символов',
      value => getPhoneNumberFromFormat(value)?.length === 10
    ),
});

export const franchiseInformationForm_1 = yupResolver(
  franchiseInfoFormSchema_1.concat(franchiseInfoFormSchema_2)
);
export const franchiseInformationForm_2 = yupResolver(franchiseInfoFormSchema_2);
