import { combineReducers } from 'redux';

import editBuyerData from './editBuyerData';
import buyerOrders from './orders';
import buyerOneOrder from './oneOrder';
import mactuses from './Mactuses';
import maxmoneyHistory from './maxmoneyHistory';
import showUserSuspendedDialog from './userSuspendedDialog';
import orderedProductsReviews from './orderedProductsReviews';

const BuyerReducer = combineReducers({
  editBuyerData,
  buyerOrders,
  buyerOneOrder,
  mactuses,
  maxmoneyHistory,
  showUserSuspendedDialog,
  orderedProductsReviews,
});

export default BuyerReducer;
