import { useEffect, useRef, memo } from 'react';

import classNames from 'classnames';

import './styles.scss';

interface Props {
  focus: boolean;
  value: string;
  isDisabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput: (event: React.FormEvent<HTMLInputElement>) => void;
  onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

const SingleOtpInput = ({ focus, isDisabled, value, ...rest }: Props): JSX.Element => {
  const input = useRef(null);
  const prevFocus = useRef({ focus });

  useEffect(() => {
    if (prevFocus.current.focus !== focus && input.current && focus) {
      input.current.focus();
      input.current.select();
    }
    return () => {
      prevFocus.current.focus = focus;
    };
  });

  return (
    <input
      className={classNames('otp-input', {
        withValue: !!value,
      })}
      maxLength={1}
      inputMode="numeric"
      ref={input}
      autoComplete="one-time-code"
      pattern="\d{9}"
      disabled={isDisabled}
      type="number"
      value={value ? value : ''}
      {...rest}
    />
  );
};

export default memo(SingleOtpInput);
