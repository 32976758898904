import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import useCheckScreen from 'utils/useCheckScreen';
import Fb from 'components/FB';
import Button from 'components/Button';

import { ReactComponent as CloseIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as MobileAppSuggestionLogo } from 'assets/icons/mobileAppSuggestionLogo.svg';

export const SuggestMobileAppPopUp = (): JSX.Element => {
  const [showMobileAppPopUp, setShowMobileAppPopUp] = useState<boolean>(false);
  const isMobile = useCheckScreen({ type: 'mobile' });
  const isTablet = useCheckScreen({ type: 'tablet' });

  useEffect(() => {
    if (isMobile || isTablet) setShowMobileAppPopUp(true);
  }, [isMobile, isTablet]);

  return (
    <>
      {showMobileAppPopUp && (
        <Fb
          row
          gap={{ xs: '15px', sm: '20px' }}
          alignCenter
          padding={{ xs: '10px 15px', sm: '10px 20px' }}
          borderRadius="0 0 10px 10px"
          bgcolor="#F4F6F9"
          sx={{
            position: 'fixed',
            width: '100%',
            height: { xs: '48px', sm: '80px' },
            top: 0,
            left: 0,
            zIndex: 1299,
          }}
        >
          <Fb
            sx={{
              width: { xs: '12px', sm: '24px' },
              minWidth: { xs: '12px', sm: '24px' },
              height: { xs: '12px', sm: '24px' },
              cursor: 'pointer',
              '& svg path': {
                stroke: '#222',
              },
            }}
          >
            <CloseIcon width="100%" height="100%" onClick={() => setShowMobileAppPopUp(false)} />
          </Fb>
          <Fb row gap={{ xs: '8px', sm: '20px' }} flex="100%" alignCenter>
            <Fb
              sx={{
                width: { xs: '28px', sm: '60px' },
                minWidth: { xs: '28px', sm: '60px' },
                height: { xs: '28px', sm: '60px' },
                cursor: 'pointer',
              }}
            >
              <MobileAppSuggestionLogo width="100%" height="100%" />
            </Fb>
            <Typography fontSize={{ xs: 10, sm: 16 }} fontWeight={400} color="#222">
              В приложении удобнее
            </Typography>
          </Fb>
          <Fb
            sx={{
              '.custom_btn_wrap': {
                width: { xs: '74px', sm: '147px' },
                '.custom-button': {
                  height: { xs: '29px', sm: '38px' },
                  fontSize: { xs: 10, sm: 16 },
                },
              },
            }}
          >
            <Button
              size="small"
              variant="filledGreen"
              onClick={() => window.open('https://onelink.to/c3h6r9', '_blank')}
              label="Установить"
            />
          </Fb>
        </Fb>
      )}
    </>
  );
};

export default SuggestMobileAppPopUp;
