import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'moment/locale/ru';
import { ToastContainer } from 'react-toastify';
import { init as initApm } from '@elastic/apm-rum';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';

import StoreProvider from 'store/StoreProvider';
import PagesWrapper from 'pages/PagesWrapper';
import ThemeProvider from 'theme/ThemeProvider';
import ToasterProvider from 'components/Toaster';
import RedirectComponent from 'components/RedirectComponent';
// import DevelopmentWarningModal from 'components/DevelopmentWarningModal';
import ScrollToTop from 'components/ScrollToTop';
import CookiesAndMaxMoneyModal from 'components/CookiesAndMaxMoneyModal';
import HelmetComponent from 'components/HelmetComponent';
import SuspendedUserModal from 'components/SuspendedUserModal';
import DynumicPupUp from 'components/DynamicPopUp';
import BuyerCongratulateModal from 'components/BuyerCongratulateModal';
import AddProductInShoppingCardAlert from 'components/AddProductInShoppingCardAlert';
import LoginRegistration from 'pages/Auth/LoginRegistration/LoginRegistration';
import {
  REACT_APP_ELASTIC_URL,
  REACT_APP_NODE_ENV,
  REACT_APP_UNLEASH_HOST,
  REACT_APP_UNLEASH_CLIENT_KEY,
  REACT_APP_UNLEASH_APPNAME,
} from 'constants/config';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import './alerts.scss';

initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'mxm-front',

  serverUrl: REACT_APP_ELASTIC_URL,

  active: REACT_APP_NODE_ENV === 'production',

  environment: REACT_APP_NODE_ENV,

  serviceVersion: '8.5.1',
});

const unleashConfig: IConfig = {
  url: REACT_APP_UNLEASH_HOST, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: REACT_APP_UNLEASH_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 0, // How often (in seconds) the client should poll the proxy for updates
  disableRefresh: true,
  appName: REACT_APP_UNLEASH_APPNAME, // The name of your application. It's only used for identifying your application
};

const App = (): React.ReactElement => {
  useEffect(() => {
    document.addEventListener('gesturestart', event => {
      event.preventDefault();
    });
  }, []);

  return (
    <>
      <FlagProvider config={unleashConfig}>
        <ToasterProvider>
          <StoreProvider>
            <ThemeProvider>
              <Router>
                <ScrollToTop />
                <DynumicPupUp />
                <CookiesAndMaxMoneyModal />
                <AddProductInShoppingCardAlert />
                <BuyerCongratulateModal />
                <LoginRegistration />
                <RedirectComponent />
                <SuspendedUserModal />
                {/* <DevelopmentWarningModal /> */}
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  newestOnTop={false}
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <HelmetComponent />
                <PagesWrapper />
              </Router>
            </ThemeProvider>
          </StoreProvider>
        </ToasterProvider>
      </FlagProvider>
    </>
  );
};

export default App;
