import { AnyAction, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { create, remove, read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  increaseWishlistProductsCount,
  decreaseWishlistProductsCount,
} from 'store/buyer/editBuyerData';
import {
  changWishlistProductIdInActualOffers,
  removeFromWishlistInActualOffers,
} from 'store/dashboard';
import {
  changWishlistProductIdInAllProducts,
  removeFromWishlistInAllProducts,
  changWishlistProductIdInCategoryProducts,
  removeFromWishlistInCategoryProducts,
} from 'store/products';

import {
  changeWishlistProductIdInBestCashback,
  removeFromWishlistInBestCashback,
  changWishlistProductIdInRandomProducts,
  removeFromWishlistInRandomProducts,
} from 'store/dashboard';
import {
  changWishlistProductIdInSimilarProducts,
  removeFromWishlistInSimilarProducts,
} from 'store/currentProduct/SimilarProducts';
import {
  changWishlistProductInCurrentProduct,
  removeFromWishlistInCurrentProduct,
} from 'store/currentProduct/ProductInfo';
import {
  AddToWishlistRequestParams,
  GetWishlistParams,
  RemoveFromWishlistRequestParams,
  WishlistInterface,
} from './types';
import {
  changWishlistProductIdInInterestedProducts,
  removeFromWishlistInInterestedProducts,
} from 'store/interested';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

const addToWishlist = async (
  { productId, from }: AddToWishlistRequestParams,
  { dispatch }: any
) => {
  const response = await create(`${REACT_APP_BUYER_URL}/wishlist`, {
    productId,
  });
  const { id: wishlistProductId }: any = response;
  dispatch(increaseWishlistProductsCount());
  if (from === 'bestCashback') {
    dispatch(
      changeWishlistProductIdInBestCashback({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'actualOffers') {
    dispatch(
      changWishlistProductIdInAllProducts({
        productId,
        wishlistProductId,
      })
    );
    dispatch(
      changWishlistProductIdInActualOffers({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'canBeInterested') {
    dispatch(
      changWishlistProductIdInInterestedProducts({
        productId,
        wishlistProductId,
      })
    );
    dispatch(
      changWishlistProductIdInRandomProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'products') {
    dispatch(
      changWishlistProductIdInAllProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'single') {
    dispatch(
      changWishlistProductInCurrentProduct({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'similarProducts') {
    dispatch(
      changWishlistProductIdInSimilarProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'categoryProducts') {
    dispatch(
      changWishlistProductIdInCategoryProducts({
        productId,
        wishlistProductId,
      })
    );
  }
  return response;
};

export const addToWishlistThunk = createAsyncThunk('wishlist/add', addToWishlist);

const removeFromWishlist = async (
  { wishlistProductId, from }: RemoveFromWishlistRequestParams,
  { dispatch }: any
) => {
  const response = await remove(`${REACT_APP_BUYER_URL}/wishlist/${wishlistProductId}`);
  dispatch(decreaseWishlistProductsCount());
  if (from === 'bestCashback') {
    dispatch(
      removeFromWishlistInBestCashback({
        wishlistProductId,
      })
    );
  } else if (from === 'actualOffers') {
    dispatch(
      removeFromWishlistInAllProducts({
        wishlistProductId,
      })
    );
    dispatch(
      removeFromWishlistInActualOffers({
        wishlistProductId,
      })
    );
  } else if (from === 'canBeInterested') {
    dispatch(
      removeFromWishlistInRandomProducts({
        wishlistProductId,
      })
    );
    dispatch(
      removeFromWishlistInInterestedProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'products') {
    dispatch(
      removeFromWishlistInAllProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'single') {
    dispatch(removeFromWishlistInCurrentProduct());
  } else if (from === 'similarProducts') {
    dispatch(
      removeFromWishlistInSimilarProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'categoryProducts') {
    dispatch(
      removeFromWishlistInCategoryProducts({
        wishlistProductId,
      })
    );
  }
  return response;
};

export const removeFromWishlistThunk = createAsyncThunk('wishlist/remove', removeFromWishlist);

const getWishlist = async ({ page, postalCode, enableDurationPostalCode }: GetWishlistParams) => {
  let url = REACT_APP_BUYER_URL;

  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  const params = {
    page,
    postalCode: undefined,
    limit: 12,
  };

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = selectedPostalCode;
  }

  return await read(`${url}/wishlist`, { params });
};

export const getWishlistThunk = createAsyncThunk('wishlist/get', getWishlist);

const initialState: WishlistInterface = {
  data: [],
  status: 'idle',
  moveProductToTheWishlistAfterOpeningThePage: false,
  meta: {},
  isWishlistItemRemoved: false,
};

const WishlistSlice = createSlice({
  initialState,
  name: 'wishlist',
  reducers: {
    setNotAvailableProduct: (state: WishlistInterface, action: PayloadAction<number>) => {
      const { payload: wishlistProductId } = action;
      const newWishlistData = state.data.map(item => {
        if (wishlistProductId === item.id) {
          return { ...item, isAvailable: false };
        }
        return item;
      });
      return {
        ...state,
        data: newWishlistData,
      };
    },
    resetWishlist: (state: WishlistInterface) => {
      const { data, status, meta } = initialState;
      return {
        ...state,
        data,
        meta,
        status,
      };
    },
    moveProductToTheWishlistAfterOpeningThePageAction: (state: WishlistInterface, action) => {
      const { payload } = action;
      return {
        ...state,
        moveProductToTheWishlistAfterOpeningThePage: payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWishlistThunk.rejected.type, (state: WishlistInterface) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(getWishlistThunk.pending.type, (state: WishlistInterface) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getWishlistThunk.fulfilled.type, (state: WishlistInterface, action: AnyAction) => {
        const {
          meta: { arg: page },
          payload: { data, meta },
        } = action;

        return {
          ...state,
          status: 'success',
          data: page !== 1 ? data : [...state.data, ...data],
          meta,
          isWishlistItemRemoved: false,
        };
      })
      .addCase(
        removeFromWishlistThunk.fulfilled.type,
        (state: WishlistInterface, action: AnyAction) => {
          const {
            meta: {
              arg: { wishlistProductId },
            },
          } = action;

          const newData = state.data.filter(({ id }) => id !== wishlistProductId);
          return {
            ...state,
            data: newData,
            isWishlistItemRemoved: true,
          };
        }
      );
  },
});

export const useWishlistData = (): WishlistInterface =>
  useAppSelector((state: RootState) => state.wishlist);

export const {
  moveProductToTheWishlistAfterOpeningThePageAction,
  resetWishlist,
  setNotAvailableProduct,
} = WishlistSlice.actions;

export default WishlistSlice.reducer;
