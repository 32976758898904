import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

export interface PreRegistrationState {
  showLoginAndRegistrationAndRegistratinModal: boolean;
  showLoginAndRegistration: boolean;
  showOtpSection: boolean;
}

const initialState: PreRegistrationState = {
  showLoginAndRegistrationAndRegistratinModal: false,
  showLoginAndRegistration: true,
  showOtpSection: false,
};

export const LoginAndRegistration = createSlice({
  name: 'loginAndRegistration',
  initialState,
  reducers: {
    setShowLoginAndRegistrationAndRegistrationModal: (
      state: PreRegistrationState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        showLoginAndRegistrationAndRegistratinModal: action.payload,
      };
    },
    setShowLoginAndRegistration: (state: PreRegistrationState, action: PayloadAction<any>) => {
      return {
        ...state,
        showLoginAndRegistration: action.payload,
      };
    },
    setshowOtpSection: (state: PreRegistrationState, action: PayloadAction<any>) => {
      return {
        ...state,
        showOtpSection: action.payload,
      };
    },
  },
});

export const useLoginAndRegistration = (): PreRegistrationState =>
  useAppSelector((state: RootState) => state.LoginAndRegistration);

export const {
  setShowLoginAndRegistrationAndRegistrationModal,
  setShowLoginAndRegistration,
  setshowOtpSection,
} = LoginAndRegistration.actions;
export default LoginAndRegistration.reducer;
