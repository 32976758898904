import approve from 'assets/icons/approve.svg';
import AmicoSvg from 'assets/icons/amico.svg';
import Promo from 'assets/icons/promo.svg';
import promoError from 'assets/icons/promo-error.svg';
import tag from 'assets/icons/tag.svg';
import trash from 'assets/icons/trash-filtration.svg';

export const tooltipNoDeliveryTitle =
  'По выбранному адресу, курьерская доставка не осуществляется.';
export const tooltipInstallmentLimitTitle =
  'Для получения рассрочки, сумма заказа должна быть не менее 3000 руб';
export const icons = { approve, AmicoSvg, Promo, promoError, tag, trash };
export const promocodeErrorMessage = 'Недействительный промокод';

export const styleLinearGradient = {
  background:
    'linear-gradient(141deg, #21C774 2.34%, #5B87DE 42.33%, #5E6DFF 52.32%, #8E00FF 89.58%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};
