import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Grid, Typography, Link, Box, Skeleton } from '@mui/material';

import {
  getContactInformationThunk,
  useContactInformationData,
} from 'store/ContactInformation/ContactInformation';
import { ContactGroupInformationType } from 'store/ContactInformation/type';
import handleImageError from 'helpers/handleImageError';

import useCheckScreen from 'utils/useCheckScreen';
import { FooterLinkInterface, footerLinks, footerIcons } from './constants';
import ROUTES from 'constants/routes';
import Fb from 'components/FB';
import MobileFooter from 'sections/Footer/MobileFooter/MobileFooter';

import PaymentOptionsImage from 'assets/icons/payment-options.svg';
// import PhoneIcon from 'assets/icons/phoneFooter.svg';
import TelegramIcon from 'assets/icons/telegram.svg';
import MaxMarketLogo from 'assets/icons/maxmarket-header-logo.svg';
import MaxMArketLogoTablet from 'assets/icons/maxmarketLogoTablet.svg';
import { ReactComponent as MobileAppQRLogo } from 'assets/icons/mobileAppQR.svg';
import { ReactComponent as PlayMarketAppLogo } from 'assets/icons/playMarket.svg';
import { ReactComponent as AppStoreAppLogo } from 'assets/icons/appStore.svg';
import defaultImg from 'assets/icons/defaultImg.svg';

import './style.scss';

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactInformationThunk());
  }, []);

  const { DASHBOARD, REQUISITES, SECURITY } = ROUTES;
  const { forBuyers, newsCompanies, forPartners }: any = footerLinks;
  const { AddressMarkIcon, MailIcon } = footerIcons;

  const isDesktop = useCheckScreen({ type: 'desktop' });
  const isTablet = useCheckScreen({ type: 'tablet' });

  const { data, contactInfoStatus } = useContactInformationData();

  const { socialMediaData, phoneData, emailData, adressData, applicationData } =
    data as ContactGroupInformationType;

  const MaxMarketApkfilePath = (applicationData && applicationData[0]?.filePath) || 'MaxMarket.apk';

  return (
    <>
      <Grid container className="footer">
        <Grid item sx={{ maxWidth: '100%', height: 'auto' }}>
          <Fb column alignStart className="footer-text-wrap">
            <Fb
              sx={{ p: 0, mb: 2, width: '80%', height: 'auto' }}
              onClick={() => navigate(DASHBOARD)}
            >
              <img
                className="cursor-pointer"
                src={!isDesktop ? MaxMArketLogoTablet : MaxMarketLogo}
                alt="MaxMarket Logo"
              />
            </Fb>
            <Typography className="footer-text">
              МаксМаркет — это новый современный маркетплейс, где вы можете не только покупать
              товары, но и в 2 клика стать продавцом. Покупать или продавать здесь просто и удобно.
              Вас ждут лучшие цены, безупречный сервис и быстрая доставка. МаксМаркет — твой шоппинг
              без колючек!
            </Typography>
          </Fb>
          <Grid item xs={12} sx={{ height: 'auto', minWidth: '100%' }}>
            <Grid
              container
              columns={20}
              justifyContent="space-between"
              sx={{ paddingTop: '40px', width: '100%' }}
            >
              <Grid item>
                <Fb column alignStart>
                  <Typography
                    sx={{ color: '#222', fontFamily: 'Golos text' }}
                    fontSize={20}
                    fontWeight={500}
                  >
                    Покупателям
                  </Typography>
                  {forBuyers.map(({ link, label }: FooterLinkInterface, index) => (
                    <NavLink
                      to={link}
                      className="footer_title"
                      key={'footer-key-forBuyers-' + index}
                    >
                      {label}
                    </NavLink>
                  ))}
                </Fb>

                <NavLink to={SECURITY}>
                  <Typography
                    sx={{
                      color: '#222 !important',
                      fontFamily: 'Golos text',
                      fontSize: '20px !important',
                      fontWeight: 500,
                      marginTop: '10px',
                    }}
                  >
                    Охрана труда
                  </Typography>
                </NavLink>
              </Grid>

              <Grid item className="item_tablet">
                <Fb column>
                  <Fb column alignStart>
                    <Typography
                      sx={{ color: '#222', fontFamily: 'Golos text' }}
                      fontSize={20}
                      fontWeight={500}
                    >
                      Партнерам
                    </Typography>
                    {forPartners.map(({ link, label }: FooterLinkInterface, index) => (
                      <NavLink
                        className="footer_title"
                        key={'footer-key-forPartners-' + index}
                        to={link}
                      >
                        {label}
                      </NavLink>
                    ))}
                  </Fb>
                  <Grid item className="Requisites_wrap_tablet">
                    <Fb
                      column
                      alignStart
                      sx={{ marginTop: '10px', fontSize: '20px !important' }}
                      className="Requisites"
                    >
                      <NavLink to={REQUISITES}>Реквизиты</NavLink>
                    </Fb>
                  </Grid>
                </Fb>
                <Grid item>
                  <Fb column alignStart sx={{ marginTop: '12px' }} className="news_tablet">
                    <Typography
                      sx={{ color: '#222', fontFamily: 'Golos text' }}
                      fontSize={20}
                      fontWeight={500}
                    >
                      Новости
                    </Typography>
                    {newsCompanies.map(({ link, label }: FooterLinkInterface, index) => (
                      <NavLink
                        to={link}
                        className="footer_title"
                        key={'footer-key-forBuyers-' + index}
                      >
                        {label}
                      </NavLink>
                    ))}
                  </Fb>
                </Grid>
              </Grid>
              <Grid item className="contacts_wrap_footer">
                <Fb column alignStart>
                  <Fb column alignStart sx={{ marginBottom: '10px' }} className="Requisites">
                    <NavLink to={REQUISITES}>Реквизиты</NavLink>
                  </Fb>
                  {(phoneData || adressData || emailData) && (
                    <Typography
                      sx={{ color: '#222', fontFamily: 'Golos text' }}
                      fontSize={20}
                      fontWeight={500}
                    >
                      Наши контакты
                    </Typography>
                  )}
                  <div className="contact_section_wrap">
                    <div className="contact_section">
                      {adressData && (
                        <Fb className="contact">
                          <img src={AddressMarkIcon} alt="phone" width={20} height={20} />

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {contactInfoStatus === 'loading' ? (
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                            ) : (
                              adressData?.map(item => {
                                const { id, key, text } = item;
                                return (
                                  <Typography
                                    key={id}
                                    sx={{ marginLeft: '7px' }}
                                    fontSize={16}
                                    color="#222"
                                  >
                                    {key} {text}
                                  </Typography>
                                );
                              })
                            )}
                          </Box>
                        </Fb>
                      )}
                      {emailData && (
                        <div className="contact_wrap">
                          <img src={MailIcon} alt="phone" width={20} height={20} />
                          <div>
                            {contactInfoStatus === 'loading' ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height="100%"
                                  width="200px"
                                  sx={{
                                    backgroundColor: 'grey.150',
                                    marginLeft: '10px',
                                  }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height="100%"
                                  width="200px"
                                  sx={{
                                    backgroundColor: 'grey.150',
                                    marginLeft: '10px',
                                  }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height="100%"
                                  width="200px"
                                  sx={{
                                    backgroundColor: 'grey.150',
                                    marginLeft: '10px',
                                  }}
                                />
                              </>
                            ) : (
                              emailData?.map(item => {
                                const { text, key, id } = item;
                                return (
                                  <Link key={id} href={`mailto: ${text}`} underline="none">
                                    <Typography
                                      sx={{ py: 2, marginLeft: '7px' }}
                                      fontSize={16}
                                      color="#222"
                                    >
                                      {key} {text}
                                    </Typography>
                                  </Link>
                                );
                              })
                            )}
                          </div>
                        </div>
                      )}

                      {/*{phoneData && (*/}
                      {/*  <div className="phone_wrap">*/}
                      {/*    <img src={PhoneIcon} alt="phone" width={20} height={20} />*/}
                      {/*    <div>*/}
                      {/*      {contactInfoStatus === 'loading' ? (*/}
                      {/*        <Skeleton*/}
                      {/*          animation="wave"*/}
                      {/*          height="100%"*/}
                      {/*          width="200px"*/}
                      {/*          sx={{*/}
                      {/*            backgroundColor: 'grey.150',*/}
                      {/*            marginLeft: '10px',*/}
                      {/*          }}*/}
                      {/*        />*/}
                      {/*      ) : (*/}
                      {/*        phoneData?.map(item => {*/}
                      {/*          const { text, key } = item;*/}
                      {/*          return (*/}
                      {/*            <Link key={item?.id} href={`tel:${text}`} underline="none">*/}
                      {/*              <Typography*/}
                      {/*                sx={{ py: 2, marginLeft: '7px' }}*/}
                      {/*                fontSize={16}*/}
                      {/*                color="#222"*/}
                      {/*              >*/}
                      {/*                {key} {text}*/}
                      {/*              </Typography>*/}
                      {/*            </Link>*/}
                      {/*          );*/}
                      {/*        })*/}
                      {/*      )}*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <Fb alignCenter className="phone_wrap">
                        <img src={TelegramIcon} alt="telegram" width={20} height={20} />
                        <div>
                          <Link href="https://t.me/Maxmarket24store" underline="none">
                            <Typography
                              sx={{ py: 2, marginLeft: '7px' }}
                              fontSize={16}
                              color="#222"
                            >
                              по всем вопросам
                            </Typography>
                          </Link>
                        </div>
                      </Fb>
                    </div>
                    {emailData && (
                      <div className="contact_wrap contact_isTablet">
                        <img src={MailIcon} alt="phone" width={20} height={20} />
                        <div>
                          {contactInfoStatus === 'loading' ? (
                            <>
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                            </>
                          ) : (
                            emailData?.map(item => {
                              const { text, key, id } = item;
                              return (
                                <Link key={id} href={`mailto: ${text}`} underline="none">
                                  <Typography sx={{ marginLeft: '7px' }} fontSize={16} color="#222">
                                    {key} {text}
                                  </Typography>
                                </Link>
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Fb>
                {socialMediaData && (
                  <div className="social_wrap">
                    <Fb
                      sx={{
                        display: 'flex',
                        marginTop: '20px',
                        gap: '20px',
                      }}
                    >
                      {contactInfoStatus === 'loading' ? (
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Skeleton
                            animation="wave"
                            height="60px"
                            width="40px"
                            sx={{
                              backgroundColor: 'grey.150',
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            height="60px"
                            width="40px"
                            sx={{
                              backgroundColor: 'grey.150',
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            height="60px"
                            width="40px"
                            sx={{
                              backgroundColor: 'grey.150',
                            }}
                          />
                        </Box>
                      ) : (
                        socialMediaData?.map(item => {
                          const { link, filePath } = item;
                          return (
                            <Link
                              key={link}
                              className="vk-fb"
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={filePath || defaultImg}
                                alt="Instagram"
                                width="28px"
                                height="28px"
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                  handleImageError(e, filePath, defaultImg);
                                }}
                              />
                            </Link>
                          );
                        })
                      )}
                    </Fb>
                  </div>
                )}
              </Grid>
              {!isTablet && (
                <Grid item xs={0} sm={12}>
                  <Fb row gap="20px">
                    <Fb maxHeight="150px">
                      <MobileAppQRLogo />
                    </Fb>
                    <Fb column gap="10px" justifyBetween alignStart maxHeight="150px">
                      <Fb column gap="10px" alignStart>
                        <Typography fontSize={24} fontWeight={500} color="#222" lineHeight="28.8px">
                          В приложении удобнее
                        </Typography>
                        <Fb column gap="4px" alignStart>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#787878"
                            lineHeight="16.8px"
                          >
                            Наведите камеру, чтобы скачать <br /> приложение MaxMarket
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#787878"
                            lineHeight="16.8px"
                          >
                            или скачайте приложение на Android{' '}
                            <a
                              href={MaxMarketApkfilePath}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={MaxMarketApkfilePath}
                              style={{ display: 'inline-block' }}
                            >
                              <Typography
                                component={'span'}
                                fontSize={14}
                                fontWeight={400}
                                color="#787878"
                                sx={{ textDecoration: 'underline' }}
                              >
                                по ссылке
                              </Typography>
                            </a>
                          </Typography>
                        </Fb>
                      </Fb>

                      <Fb row gap="10px" height="40px">
                        <a
                          href="https://play.google.com/store/apps/details?id=ru.maxmarket.android&hl=ru&gl=US"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <PlayMarketAppLogo />
                        </a>
                        <a
                          href="https://apps.apple.com/ru/app/maxmarket/id6462699376"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <AppStoreAppLogo />
                        </a>
                      </Fb>
                    </Fb>
                  </Fb>
                </Grid>
              )}
            </Grid>

            <Box
              color="#4F4F4F"
              sx={{
                pt: 3,
                height: '100px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginTop: !isDesktop ? '0px' : '30px',
              }}
            >
              <img className="payment-img" src={PaymentOptionsImage} alt="payment options" />
              <div className="hr"></div>
              <p className="footer-end">
                © 2016-{new Date().getFullYear()} Маркетплейс нового поколения “MAXMARKET”
              </p>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <MobileFooter />
    </>
  );
};

export default Footer;
