const deliveryMethods = {
  delivery: 1,
  pickUp: 2,
};

export const deliveryMethodData = {
  [deliveryMethods.delivery]: {
    label: 'Доставка курьером',
  },
  [deliveryMethods.pickUp]: {
    label: 'Самовывоз',
  },
};

export default deliveryMethods;
