import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';

export interface BuyerOrders {
  limit?: number;
  page?: number | string;
  statuses?: string;
}

export const getBuyerOrders = async (params: BuyerOrders) =>
  await read(`${REACT_APP_BUYER_URL}/orders`, { params });
