export const CONTACT_INFORMATION_TYPE = {
  CONTACT_INFO_TYPE_ADDRESS: 1,
  CONTACT_INFO_TYPE_EMAIL: 2,
  CONTACT_INFO_TYPE_PHONE_NUMBER: 3,
  CONTACT_INFO_TYPE_SOCIAL_MEDIA: 4,
  CONTACT_INFO_TYPE_FILE: 5,
};

export const CONTACT_TYPE_KEY = {
  [CONTACT_INFORMATION_TYPE.CONTACT_INFO_TYPE_ADDRESS]: 'adressData',
  [CONTACT_INFORMATION_TYPE.CONTACT_INFO_TYPE_EMAIL]: 'emailData',
  [CONTACT_INFORMATION_TYPE.CONTACT_INFO_TYPE_PHONE_NUMBER]: 'phoneData',
  [CONTACT_INFORMATION_TYPE.CONTACT_INFO_TYPE_SOCIAL_MEDIA]: 'socialMediaData',
  [CONTACT_INFORMATION_TYPE.CONTACT_INFO_TYPE_FILE]: 'applicationData',
};
