import React, { FC } from 'react';

import { authStorage } from 'store/storage';

interface PropType {
  component: React.FC;
}

const PrivateRoute: FC<PropType> = ({ component: Component }) => {
  const { accessToken } = authStorage.get();
  if (accessToken) return <Component />;
};

export default PrivateRoute;
