import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    bordered: true;
  }

  interface ButtonPropsColorOverrides {
    naturalGrey: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    naturalGrey: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    naturalGrey: Palette['primary'];
    primary2: string;
  }

  interface PaletteOptions {
    naturalGrey: PaletteOptions['primary'];
    primary2: string;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Golos Text',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#30B46F',
      dark: '#218751',
    },
    primary2: '#B150C4',
    error: {
      main: '#F44336',
    },
    success: {
      main: '#4CAF50',
    },
    background: {
      default: '#F6F8F9',
    },
    naturalGrey: {
      main: '#96A2A9',
      light: '#E5E8EA',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: 10,
          textTransform: 'capitalize',
          '&.Mui-selected': {
            color: '#333333',
            fontWeight: '500',
            borderRadius: '5px',
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        height: '100%',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          paddingRight: '5px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100% !important',
          color: '#828282',
          fontSize: '14px',
          justifyItems: 'center',
          border: '#DCDEE1 !important',
          '&.Mui-focused > fieldset': {
            border: '2px solid #30B46F',
          },
          '&.Mui-error > fieldset': {
            borderColor: '#F44336 !important',
          },
          '& input:disabled': {
            color: '#828282',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'bordered' },
          style: ({ theme, ...props }) => {
            const params: { ownerState?: any } = { ...props };

            const color =
              params.ownerState.color === '#30B46F'
                ? theme.palette.naturalGrey.main
                : theme.palette.text.primary;

            return {
              color,
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
              ':disabled': {
                color: '#70D29F',
                backgroundColor: theme.palette.naturalGrey.light,
                borderColor: theme.palette.naturalGrey.light,
              },
              '& svg': {
                color: theme.palette.naturalGrey.main,
              },
            };
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        contained: {
          color: '#FFFFFF',
          width: '100% important',
          backgroundColor: '#FFFFF',
          '&:hover': {
            backgroundColor: '#30B46F',
          },
          ':disabled': {
            color: '#FFFFF',
            backgroundColor: '#70D29F',
            border: '1px solid #70D29F',
          },
        },
        outlined: {
          color: '#30B46F',
          width: '100% important',
          backgroundColor: '#FFFFFF',
          borderColor: '#30B46F',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            borderColor: '#30B46F',
          },
          ':disabled': {
            color: '#FFFFF',
            borderColor: '#30B46F',
          },
        },
        root: {
          fontWeight: '600',
          fontSize: 15,
          lineHeight: '20px',
          textTransform: 'none',
        },
        sizeLarge: {
          height: 48,
          padding: '10px 22px',
        },
        sizeMedium: {
          width: 60,
          height: 42,
          padding: '8px 22px',
        },
        sizeSmall: {
          padding: '5px 22px',
        },
      },
    },
  },
});

export default theme;
