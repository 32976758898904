import React, { FC, memo, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import { Divider, IconButton, Menu, Typography } from '@mui/material';
import classNames from 'classnames';

import { logoutThunk } from 'store/auth/login';
import { authStorage } from 'store/storage';
import Fb from 'components/FB';
import useCheckScreen from 'utils/useCheckScreen';
import accountTabs from 'constants/accountTabs';
import userTypes from 'constants/userTypes';
import ROUTES from 'constants/routes';

import UserIcon from 'assets/icons/userHeader.svg';
import defaultMactus from 'assets/images/mactus001.png';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg';
import { ReactComponent as StarReview } from 'assets/icons/star_profile.svg';
import { ReactComponent as AccountCircleOutlinedIcon } from 'assets/icons/addres.svg';
import { ReactComponent as ShoppingCartOutlinedIcon } from 'assets/icons/orders.svg';
import { ReactComponent as QuestionMarkRoundedIcon } from 'assets/icons/feedback.svg';
import { setShowLoginAndRegistrationAndRegistrationModal } from 'store/auth/LoginAndregistration';

interface Props {
  buyerAvatar: string;
  sellerAvatar: string;
  shopLogo: string;
}

const ProfileMenu: FC<Props> = ({ buyerAvatar, sellerAvatar, shopLogo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const enableDurationPostalCode = useFlag('enable_duration_postal_code');
  const [anchorElUser, setAnchorElUser] = useState(null);
  const isMobile = useCheckScreen({ type: 'mobile' });
  const { pathname } = useLocation();

  const { buyer, shop, franchise, wholesaler } = userTypes;
  const { DASHBOARD } = ROUTES;
  const { userType } = authStorage.get();
  const isLoggedIn = !!authStorage.get().accessToken;
  const isBuyerType = userType === buyer;
  const isShopType = userType === shop;
  const isFranchiseType = userType === franchise;
  const isWholesalerType = userType === wholesaler;

  let image = UserIcon;

  if (isShopType) {
    image = shopLogo;
  } else if (isBuyerType) {
    image = buyerAvatar;
  } else if (isFranchiseType || isWholesalerType) {
    image = sellerAvatar;
  }

  const showUserMenu = Boolean(anchorElUser);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    handleCloseUserMenu();
    localStorage.removeItem('city');
    localStorage.removeItem('GPS');
    dispatch(
      logoutThunk({
        goToSignIn: () => navigate(DASHBOARD),
        enableDurationPostalCode,
        pathname,
      })
    );
  };

  const handleProfileMenuRoute = (path: string, parentPth = '/account/') =>
    navigate(parentPth + path);

  const ProfileMenuItems = useMemo(() => {
    const items = [
      {
        title: 'Профиль',
        Icon: AccountCircleOutlinedIcon,
        onClick: () => handleProfileMenuRoute(accountTabs.PERSONAL_INFO),
      },
      {
        title: 'Список заказов',
        Icon: ShoppingCartOutlinedIcon,
        onClick: () =>
          handleProfileMenuRoute(isBuyerType ? accountTabs.ORDERS : accountTabs.WHOLESALER_ORDERS),
      },
      {
        title: 'Отзывы',
        Icon: StarReview,
        onClick: () => handleProfileMenuRoute(accountTabs.REVIEWS),
      },
      {
        title: 'Обратная связь',
        Icon: QuestionMarkRoundedIcon,
        onClick: () => handleProfileMenuRoute(accountTabs.CONNECT),
      },
    ];

    if (isBuyerType) {
      items.splice(2, 0, {
        title: 'Избранное',
        Icon: Heart,
        onClick: () => handleProfileMenuRoute(accountTabs.WISHLIST),
      });
    }
    return items;
  }, [isBuyerType]);

  return (
    <>
      <IconButton
        className="profil-icon-wrapper"
        sx={{
          borderRadius: '8px',
          background: '#F4F6F9',
          width: isMobile ? '32px' : '44px',
          height: isMobile ? '32px' : '44px',
        }}
        onClick={
          isLoggedIn
            ? handleOpenUserMenu
            : () => dispatch(setShowLoginAndRegistrationAndRegistrationModal(true))
        }
        disableRipple
      >
        <img
          width={24}
          height={24}
          src={image || defaultMactus}
          className={classNames({
            'header-user-image': isBuyerType || isWholesalerType || isFranchiseType || isShopType,
          })}
          alt="image"
        />
      </IconButton>

      <Menu
        className="menu-bar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showUserMenu}
        onClose={handleCloseUserMenu}
      >
        <Fb flexDirection={'column'} className="menu-wrap" gap={'10px'} p={'20px'}>
          {isLoggedIn && (
            <>
              {ProfileMenuItems?.map(({ title, Icon, onClick }) => (
                <Fb
                  key={title}
                  className="menu-item cursor-pointer"
                  onClick={() => {
                    handleCloseUserMenu();
                    onClick();
                  }}
                >
                  <Icon />
                  <Typography color={'#222'} fontSize={16} lineHeight={'normal'}>
                    {title}
                  </Typography>
                </Fb>
              ))}
              <Divider />
              <Fb className="menu-item cursor-pointer" onClick={logout}>
                <ExitIcon />
                <Typography>Выйти</Typography>
              </Fb>
            </>
          )}
        </Fb>
      </Menu>
    </>
  );
};

export default memo(ProfileMenu);
