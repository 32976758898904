import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDynumicPupUpThunk } from 'store/dynumicPupUp';
import {
  setShowLoginAndRegistration,
  setShowLoginAndRegistrationAndRegistrationModal,
} from 'store/auth/LoginAndregistration';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/Modal';
import Routes from 'constants/routes';

import maxMoneyModal from 'assets/icons/maxMony-modal.svg';

import './style.scss';

const MaxMoneyInfoModal = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { MAX_MONEY_INFO } = Routes;

  const onCloseModal = () => {
    setOpen(false);
    dispatch(getDynumicPupUpThunk());
  };
  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!localStorage.getItem('uid')) {
      localStorage.setItem('uid', uuidv4());
      openModal();
    }
  }, []);

  const handleMoveToRefistrationPage = () => {
    onCloseModal();
    dispatch(setShowLoginAndRegistrationAndRegistrationModal(true));
    dispatch(setShowLoginAndRegistration(false));
  };

  const moveToMaxMoneyInfoPage = () => {
    navigate(MAX_MONEY_INFO);
    onCloseModal();
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <div className="maxMoney-info-modal">
        <img className="maxMoneyModalImg" src={maxMoneyModal} alt="maxMoneyModal" />
        <div className="text">300 максмани за регистрацию</div>
        <button className="but" onClick={handleMoveToRefistrationPage}>
          Регистрация
        </button>
        <p className="text-footer">
          *300 баллов = 300 рублей, накопленными баллами может быть оплачено до 100% заказа. Акция
          распространяется только на товары от MaxMarket. Узнать подробнее о бонусной программе
          можно по
          <span onClick={moveToMaxMoneyInfoPage} className="link">
            ссылке
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default MaxMoneyInfoModal;
