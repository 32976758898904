import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  title: {
    font: 'normal normal 500 28px/36px Golos Text !important',
    marginBottom: '15px !important',
    textAlign: 'center',
  },
  phoneNumber: {
    textAlign: 'center',
    marginRight: '10px',
  },
  radioButton: {
    '&, &.Mui-checked': { color: '#000 !important' },
    ':checked': { color: '#000 !important' },
  },
  buyerButton: {
    '&.Mui-selected': {
      borderColor: '#000 !important',
      backgroundColor: '#000 !important',
    },
    border: '1px solid #000 !important',
    color: '#000 !important',
  },
}));
