import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';

import { ReviewsOrderedProducts } from './types';

const getOrderedProductsReviews = async (page: number) => {
  const responsOrderedProducts = await read(
    `${REACT_APP_BUYER_URL_V2}/ordered-products?page=${page}`
  );
  const reviewIds = responsOrderedProducts?.data?.map(e => e.reviewId).filter(e => e);

  if (reviewIds.length) {
    const respons = await read(
      `${REACT_APP_BUYER_URL}/reviews-images?reviewIds=${reviewIds.join()}`
    );
    responsOrderedProducts.data.forEach(review => {
      review.images = respons[review.reviewId] || null;
    });
  }
  return responsOrderedProducts;
};

export const getOrderedProductsReviewsThunk = createAsyncThunk(
  'buyerOrdered/reviews',
  getOrderedProductsReviews
);

const initialState: ReviewsOrderedProducts = {
  data: [],
  meta: {},
  status: 'idle',
};

const orderedProductsReviewsSlice = createSlice({
  name: 'orderedProductsReviews',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getOrderedProductsReviewsThunk.fulfilled.type,
        (state: ReviewsOrderedProducts, action: PayloadAction<ReviewsOrderedProducts>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data: [...state.data, ...data],
            meta,
            status: 'success',
          };
        }
      )
      .addCase(getOrderedProductsReviewsThunk.pending.type, (state: ReviewsOrderedProducts) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getOrderedProductsReviewsThunk.rejected.type, (state: ReviewsOrderedProducts) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export const useOrderedProductsReviewsData = (): ReviewsOrderedProducts =>
  useAppSelector((state: RootState) => state.buyer.orderedProductsReviews);

export const { reset } = orderedProductsReviewsSlice.actions;

export default orderedProductsReviewsSlice.reducer;
