import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

import { ReactComponent as RadioButtonCheckedIcon } from 'assets/icons/radioButtonChecked.svg';
import { ReactComponent as RadioButtonDisabled } from 'assets/icons/radioButtonDisabled.svg';
import { ReactComponent as RadioButtonCheckedGreen } from 'assets/icons/radioButtonCheckedGreen.svg';

interface IRadio extends RadioProps {
  disabled: boolean;
}

const RadioButtonChecked = (props: IRadio) => {
  const { disabled, checkedIcon } = props;
  return disabled ? (
    <RadioButtonDisabled />
  ) : checkedIcon ? (
    <RadioButtonCheckedGreen />
  ) : (
    <RadioButtonCheckedIcon />
  );
};

const Icon = styled('span')(() => ({
  background: '#FBFBFC',
  border: '1.5px solid #EAEAEA',
  borderRadius: '50%',
  height: '16px',
  width: '16px',
  'input:disabled ~ &': {
    background: '#FBFBFC',
    border: '1.5px solid #D9D9D9',
  },
}));

const RadioButton = (props: RadioProps) => {
  const { disabled, checkedIcon } = props;
  return (
    <Radio
      disableRipple
      color="default"
      sx={{
        py: 0,
      }}
      checkedIcon={<RadioButtonChecked disabled={disabled} checkedIcon={checkedIcon} />}
      icon={<Icon />}
      {...props}
    />
  );
};

export default RadioButton;
