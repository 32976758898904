import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Typography, InputAdornment } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

import { REACT_APP_NODE_ENV } from 'constants/config';
import Form, { FormInput, useForm } from 'components/Form';
import Fb from 'components/FB';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { setshowOtpSection, useLoginAndRegistration } from 'store/auth/LoginAndregistration';
import RegistrationAndLoginButtons from 'components/RegistrationAndLoginButtons';
import RadioButton from 'components/custom/RadioButton/index';
import CustomButton from 'components/Button';
import Checkbox from 'components/custom/Checkbox';
import { defaultParamsPreRegistrationAndLogin } from 'constants/auth/preRegistrationAndLogin';
import ROUTES from 'constants/routes';
import { checkPhonePreRegistrationThunk, resetPreRegistration } from 'store/auth/preRegistration';
import VerificationPopup from './OTPverification';
import { registrationSchema } from 'resolvers';

import { ReactComponent as CheckIconGreen } from 'assets/icons/checkIconGreen.svg';
import { ReactComponent as RadioButtonCheckedGreen } from 'assets/icons/radioButtonCheckedGreen.svg';

import useStyles from './styles';
import './style.scss';

const RegistrationPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState(1);
  const [agreeWithTermsChecked, setAgreeWithTermsChecked] = useState<boolean>(true);
  const [hideErrorMessage, setHideErrorMessage] = useState<boolean>(false);

  const {
    preRegistration: { status, errorMessage, phoneIsValid },
  } = useAppSelector((state: RootState) => state);

  const { showOtpSection } = useLoginAndRegistration();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useForm<{
    phoneNumber?: string;
  }>({
    resolver: registrationSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetPreRegistration());
    };
  }, []);

  // remove special characters for backend
  const phoneNumber = watch('phoneNumber').replace(/\D/g, '') || '';

  const handleChangeType = event => setType(event.target.value);

  const onSubmit = () => {
    setHideErrorMessage(true);
    dispatch(resetPreRegistration());
    dispatch(
      checkPhonePreRegistrationThunk({
        phoneNumber: +phoneNumber,
        ...defaultParamsPreRegistrationAndLogin,
      })
    );
  };

  useEffect(() => {
    if (phoneNumber.length < 10) {
      setHideErrorMessage(false);
    }
  }, [phoneNumber]);

  const validationProps = useCallback(
    (field: string) => {
      const fieldError = errors?.[field];
      return {
        name: field,
        type: field,
        error: !!fieldError,
        helperText: fieldError?.message,
      };
    },
    [errors]
  );

  useEffect(() => {
    dispatch(setshowOtpSection(phoneIsValid));
  }, [phoneIsValid]);

  return showOtpSection ? (
    <Fb justifyCenter alignCenter flex1 sx={{ p: 2 }}>
      <VerificationPopup
        text={`Мы отправили код подтверждения на номер`}
        phoneNumber={+phoneNumber}
        type={type}
      />
    </Fb>
  ) : (
    <Fb justifyCenter alignCenter flex1 sx={{ backgroundColor: '#FFF', maxWidth: '450px' }}>
      <Fb
        sx={{
          maxWidth: '100%',
          borderRadius: 5,
        }}
      >
        <Form
          style={{
            maxWidth: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <RegistrationAndLoginButtons />
          <Fb
            sx={{
              textAlign: 'center',
              flexDirection: 'column',
              marginTop: '20px',
              color: '#222',
              fontSize: {
                md: '18px',
                sx: '14px',
              },

              fontFamily: 'Golos Text',
              fontWeight: 400,
            }}
          >
            <p> Введите номер телефона,</p>
            <p> мы отправим вам СМС с кодом подтверждения</p>
          </Fb>
          <Fb justifyCenter column sx={{ p: 3 }}>
            <Stack
              spacing={'20px'}
              sx={{
                maxWidth: '100%',
              }}
            >
              <div className="account_input_wrapper">
                <FormInput
                  resetField={resetField}
                  variant="outlined"
                  control={control}
                  name="phoneNumber"
                  placeholder="(___) - ___ - __ - __"
                  mask="(999) - 999 - 99 - 99"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        style={{ background: '#fff', padding: '18px 20px', borderRadius: '10px' }}
                        position="start"
                      >
                        +7
                      </InputAdornment>
                    ),
                  }}
                  {...validationProps('phoneNumber')}
                />
              </div>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={type}
                onChange={handleChangeType}
              >
                <FormControlLabel
                  sx={{
                    color: '#222',
                    fontSize: {
                      md: '18px',
                      sx: '14px',
                    },
                    fontFamily: 'Golos text',
                  }}
                  value="1"
                  label="Я покупатель"
                  control={
                    <RadioButton
                      className={classes.radioButton}
                      checkedIcon={<RadioButtonCheckedGreen />}
                    />
                  }
                />
                {REACT_APP_NODE_ENV === 'production' ? (
                  <a className="salesman" href="https://maxmarket24.ru/personal/login/regsaler.php">
                    <div className="radio"></div>Я предприниматель
                  </a>
                ) : (
                  <FormControlLabel
                    sx={{
                      colo: '#222',
                      fontSize: {
                        md: '18px',
                        sx: '14px',
                      },
                      fontFamily: 'Golos text',
                    }}
                    value="2"
                    label="Я предприниматель"
                    control={
                      <RadioButton
                        className={classes.radioButton}
                        checkedIcon={<RadioButtonCheckedGreen />}
                      />
                    }
                  />
                )}
              </RadioGroup>
            </Stack>
            <Stack spacing="6px" sx={{ mt: 3 }}>
              <CustomButton
                label="Получить код"
                variant="filledGreen"
                disabled={status === 'loading' || !agreeWithTermsChecked}
                size="fullWidth"
              />
              {hideErrorMessage && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#db287e',
                    height: '40px',
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </Stack>

            <Fb alignStart sx={{ marginTop: '10px' }}>
              <Checkbox
                checkedIcon={<CheckIconGreen />}
                checked={agreeWithTermsChecked}
                onChange={(_, checked) => setAgreeWithTermsChecked(checked)}
                sx={{
                  mt: '2px',
                  '&$checked': {
                    svg: {
                      fill: 'red',
                    },
                  },
                }}
              />
              <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#8A8A8A' }}>
                {' '}
                <Link className="link" to={ROUTES.PLACINGANORDER} target="_blank">
                  Согласен с условиями Правил пользования торговой площадкой и правилами возврата
                </Link>
              </Typography>
            </Fb>
          </Fb>
        </Form>
      </Fb>
    </Fb>
  );
};

export default RegistrationPage;
