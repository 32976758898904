import CookiesModal from 'components/CookiesModal';
import MaxMoneyInfoModal from 'components/MaxMoneyInfoModal';
import SuggestMobileAppPopUp from 'components/SuggestMobileAppPopUp';

const CookiesAndMaxMoneyModal = () => {
  return (
    <div>
      <SuggestMobileAppPopUp />
      <CookiesModal />
      <MaxMoneyInfoModal />
    </div>
  );
};

export default CookiesAndMaxMoneyModal;
