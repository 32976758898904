import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { read, create } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Meta, Status } from 'store/types';

import { ProductReview, GetProductReviewsParams, CreateReviewData } from './types';

const getProductReviews = async ({ id, page = 1 }: GetProductReviewsParams) => {
  return await read(`${REACT_APP_BUYER_URL}/products/${id}/reviews?page=${page}&limit=8`);
};

export const getProductReviewsThunk = createAsyncThunk('productReviews/get', getProductReviews);
const createProductReview = async (data: CreateReviewData) =>
  await create(`${REACT_APP_BUYER_URL}/reviews`, data);

export const createProductReviewThunk = createAsyncThunk(
  'productReviews/create',
  createProductReview
);

interface ProductReviewsState {
  data: Array<ProductReview>;
  meta: Meta;
  status: Status;
  createStatus: Status;
}

const initialState = {
  data: [],
  meta: {},
  createStatus: 'idle',
  status: 'idle',
} as ProductReviewsState;

const currentProductReviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    resetReview: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getProductReviewsThunk.fulfilled.type, (state: ProductReviewsState, action: any) => {
        const {
          meta: {
            arg: { addToRest },
          },
        } = action;

        const { data, meta } = action.payload;
        return {
          ...state,
          data: !addToRest ? [...state.data, ...data] : data,
          meta,
          status: 'success',
        };
      })
      .addCase(getProductReviewsThunk.pending.type, (state: ProductReviewsState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getProductReviewsThunk.rejected.type, (state: ProductReviewsState) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(createProductReviewThunk.fulfilled.type, (state: ProductReviewsState) => {
        return {
          ...state,
          createStatus: 'success',
        };
      })
      .addCase(createProductReviewThunk.pending.type, (state: ProductReviewsState) => {
        return {
          ...state,
          createStatus: 'loading',
        };
      })
      .addCase(createProductReviewThunk.rejected.type, (state: ProductReviewsState) => {
        return {
          ...state,
          createStatus: 'failed',
        };
      });
  },
});

export const useProductReviewsData = (): ProductReviewsState =>
  useAppSelector((state: RootState) => state.currentProduct.reviews);

export const { resetReview } = currentProductReviewSlice.actions;

export default currentProductReviewSlice.reducer;
