import React, { Ref, forwardRef, useState } from 'react';

import { Controller, Control } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/inputErrorIcon.svg';

import './style.scss';

interface FormInputProps {
  control: Control;
  mask?: string;
  maskChar?: string;
  resetField?: any;
  maskPlaceholder?: null;
  inpValues?: string;
}

const FormInput = forwardRef(
  (
    {
      name,
      disabled,
      label,
      type,
      control,
      placeholder,
      resetField,
      maskPlaceholder,
      mask,
      inpValues = '',
      InputProps,
      ...props
    }: FormInputProps & TextFieldProps,
    ref: Ref<HTMLInputElement>
  ): JSX.Element => {
    const { error, select, className } = props;
    const [isFocused, setFocused] = useState<boolean>(!!inpValues?.length);

    const handleFocus = () => setFocused(true);

    const handleBlur = (event: any) => {
      if (!event.target.value.length) {
        setFocused(false);
      }
    };

    if (!name || !control) {
      return <></>;
    }

    if (error) {
      InputProps = {
        ...InputProps,
        endAdornment: (
          <InputAdornment
            className={classNames({ 'select-error-icon': select && error })}
            position="end"
          >
            <ErrorIcon className="cursor-pointer" onClick={() => resetField(name)} />
          </InputAdornment>
        ),
      };
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ...field } }) => (
          <div className={`input_wrap`}>
            <label htmlFor={name} className={`${isFocused ? 'hidden' : ''}`}>
              {label} {label && !isFocused && <span className={'asterisk'}>*</span>}
            </label>
            {mask ? (
              <InputMask
                mask={mask}
                maskPlaceholder={maskPlaceholder}
                value={value}
                {...field}
                disabled={disabled}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <TextField
                  autoComplete="off"
                  {...props}
                  InputProps={InputProps}
                  ref={ref}
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  className={classNames(`account_input form-input ${className}`, {
                    'select-text-field': select,
                  })}
                />
              </InputMask>
            ) : (
              <TextField
                autoComplete="off"
                {...props}
                {...field}
                InputProps={InputProps}
                ref={ref}
                type={type}
                value={value}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={disabled}
                placeholder={placeholder}
                className={classNames(`account_input ${className}`, {
                  'select-text-field': select,
                })}
              />
            )}
          </div>
        )}
      />
    );
  }
);

FormInput.displayName = 'FormInput';

FormInput.defaultProps = {
  fullWidth: true,
};

export default React.memo(FormInput);
