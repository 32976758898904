import React, { useEffect, useMemo, useState, FC, useRef, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Button,
  ClickAwayListener,
  Popper,
  MenuList,
  Fade,
  MenuItem,
  Grid,
  Box,
} from '@mui/material';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import {
  getParentCategoriesThunk,
  getSubCategoryThunk,
  resetSubCategories,
  useCatalogData,
} from 'store/catalog';
import { AppDispatch } from 'store';
import ROUTES from 'constants/routes';
import { REACT_APP_NODE_ENV, REACT_APP_CATEGORY_ICONS } from 'constants/config';
import MaxMarketLoader from 'components/MaxMarketLoader';
import { getCatalogCategoriesListUrl } from 'helpers/getProductByCategoriesList';
import useCheckScreen from 'utils/useCheckScreen';

import { ReactComponent as CatalogMenuIcon } from 'assets/icons/catalog-menu.svg';
import categoryItem from 'assets/icons/category-item.svg';
import { ReactComponent as BrandIcon } from 'assets/icons/brand-icon.svg';
import ArrowDown from 'assets/icons/chevron-arrow-down.svg';

import SubCategory from './SubCategory';

import './style.scss';

interface Props {
  anchorRef: any;
}

const Catalog: FC<Props> = ({ anchorRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const observer = useRef(null);
  const [page, setPage] = useState<number>(1);
  const subRef = React.useRef<HTMLDivElement>(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [parentId, setParentId] = useState(null);

  const isDesktop = useCheckScreen({ type: 'desktop' });
  const { MOBILECATALOG } = ROUTES;
  const {
    parentCategories: {
      data: catalogData,
      meta: { lastPage = 1 },
      status: catalogStatus,
    },
    subCategory: { data: subCategory, banner },
  } = useCatalogData();

  const showCatalogMenu = Boolean(anchorElNav);

  useEffect(() => {
    dispatch(getParentCategoriesThunk(page));
  }, [page]);

  useEffect(() => {
    let requestGetSubCategory;
    if (parentId) {
      requestGetSubCategory = dispatch(getSubCategoryThunk(parentId));
    }
    return () => {
      if (requestGetSubCategory) {
        requestGetSubCategory.abort();
      }
    };
  }, [parentId]);

  useEffect(() => {
    if (showCatalogMenu) {
      const patentId = catalogData[0]?.id;
      setParentId(patentId);
    }
  }, [showCatalogMenu]);

  const handleOpenCatalog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenSubCatalog = (id: number) => () => {
    setParentId(id);
    subRef.current?.scrollTo(0, 0);
  };

  const handleCloseCatalogMenu = useCallback(() => {
    setAnchorElNav(null);
    setParentId(null);
    dispatch(resetSubCategories());
  }, []);

  const handleHoverBrand = () => {
    setParentId(null);
  };

  const handleOpenBrandsPage = () => {
    handleCloseCatalogMenu();
    navigate(`/brands`);
  };

  const lastCategoryElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(prevPageNumber =>
            lastPage === prevPageNumber ? prevPageNumber : prevPageNumber + 1
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [catalogData]
  );

  const catalogMenu = useMemo(
    () => (
      <Popper
        open={showCatalogMenu}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        className="catalog"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={10}>
            <div className="catalog_items_wrap">
              <ClickAwayListener onClickAway={handleCloseCatalogMenu}>
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    width: '100%',
                    height: '88vh',
                    padding: '20px 0px 44px 0px',
                  }}
                >
                  <Grid item className="catalog__main">
                    {catalogStatus === 'loading' ? (
                      <MaxMarketLoader />
                    ) : (
                      <MenuList
                        autoFocusItem={showCatalogMenu}
                        id="catalog-menu"
                        aria-labelledby="catalog-button"
                      >
                        {catalogData?.map((catalogItem, i) => {
                          const { id, name } = catalogItem;
                          let iconPath = catalogItem.iconPath;

                          if (
                            (REACT_APP_NODE_ENV === 'redesign' ||
                              REACT_APP_CATEGORY_ICONS === 'redesign') &&
                            iconPath
                          ) {
                            iconPath = iconPath.replace(
                              'category-icons/',
                              'category-icons/redesign/'
                            );
                          }

                          return (
                            <Link key={id} to={getCatalogCategoriesListUrl(id)}>
                              <MenuItem
                                className={classNames('menu__item', {
                                  'menu__item-active': id === parentId,
                                })}
                                ref={catalogData.length === i + 1 ? lastCategoryElementRef : null}
                                disableRipple
                                onClick={handleCloseCatalogMenu}
                                onMouseOver={handleOpenSubCatalog(id)}
                              >
                                <Box display="flex" alignItems="center">
                                  <SVG src={iconPath || categoryItem} />
                                  <span className="parent-category-name">{name}</span>
                                </Box>
                                {id === parentId && <img src={ArrowDown} />}
                              </MenuItem>
                            </Link>
                          );
                        })}
                        <MenuItem
                          className="menu__item"
                          disableRipple
                          onMouseOver={handleHoverBrand}
                          onClick={handleOpenBrandsPage}
                        >
                          <Box display="flex" alignItems="center">
                            <BrandIcon />
                            <span className="parent-category-name">Бренды</span>
                          </Box>
                        </MenuItem>
                      </MenuList>
                    )}
                  </Grid>

                  {parentId && subCategory && !Array.isArray(subCategory.subCategories) && (
                    <SubCategory
                      subCategory={subCategory}
                      banner={banner}
                      subRef={subRef}
                      handleCloseCatalogMenu={handleCloseCatalogMenu}
                    />
                  )}
                </Grid>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    ),
    [showCatalogMenu, anchorElNav, catalogData, parentId, subCategory]
  );

  return (
    <Grid item className="catalog-menu-wrap">
      <Button
        id="catalog-button"
        aria-controls={showCatalogMenu ? 'catalog-menu' : undefined}
        aria-expanded={Boolean(showCatalogMenu)}
        aria-haspopup="true"
        onClick={isDesktop ? handleOpenCatalog : () => navigate(MOBILECATALOG)}
        variant="contained"
        className="catalog-menu-button"
      >
        <CatalogMenuIcon width={16} height={12} />
        {isDesktop && 'Каталог'}
      </Button>
      {catalogMenu}
    </Grid>
  );
};

export default Catalog;
