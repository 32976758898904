import { useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Stack, Typography, InputAdornment } from '@mui/material';

import { useAppDispatch } from 'store/hooks';
import { checkPhonePreLoginThunk, resetPreLogin, usePreLoginData } from 'store/auth/preLogin';
import { setshowOtpSection, useLoginAndRegistration } from 'store/auth/LoginAndregistration';
import Form, { FormInput, useForm } from 'components/Form';
import RegistrationAndLoginButtons from 'components/RegistrationAndLoginButtons';
import CustomButton from 'components/Button';
import Checkbox from 'components/custom/Checkbox';
import Fb from 'components/FB';
import { loginSchema } from 'resolvers';
import { defaultParamsPreRegistrationAndLogin } from 'constants/auth/preRegistrationAndLogin';
import ROUTES from 'constants/routes';

import Popup from './OTPverification';
import AccountRecoverModal from './AccountRecoverModal';

import { ReactComponent as CheckIconGreen } from 'assets/icons/checkIconGreen.svg';

const LoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [agreeWithTermsChecked, setAgreeWithTermsChecked] = useState<boolean>(true);
  const [showAccountRecoverModal, setShowAccountRecoverModal] = useState(false);
  const [hideErrorMessage, setHideErrorMessage] = useState<boolean>(false);
  const { status, errorMessage, phoneIsValid, errorCode } = usePreLoginData();
  const { showOtpSection } = useLoginAndRegistration();
  const { redirectionUrl = '' } = state || {};
  const toggleAccountRecoverModal = () => setShowAccountRecoverModal(!showAccountRecoverModal);
  const {
    watch,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<{
    phoneNumber?: string;
  }>({
    resolver: loginSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });
  useEffect(() => {
    return () => {
      dispatch(resetPreLogin());
    };
  }, []);

  // remove special characters for backend
  const phoneNumber = watch('phoneNumber').replace(/\D/g, '') || '';

  const onSubmit = () => {
    setHideErrorMessage(true);
    dispatch(resetPreLogin());
    const isDeleted = errorCode === 707;
    dispatch(
      checkPhonePreLoginThunk({
        phoneNumber: +phoneNumber,
        ...defaultParamsPreRegistrationAndLogin,
        isDeleted,
      })
    );
  };

  useEffect(() => {
    if (phoneNumber.length < 10) {
      setHideErrorMessage(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (errorCode === 707) {
      toggleAccountRecoverModal();
    }
  }, [errorCode]);

  useEffect(() => {
    if (!showAccountRecoverModal) {
      dispatch(resetPreLogin());
    }
  }, [showAccountRecoverModal]);

  const validationProps = useCallback(
    (field: string) => {
      const fieldError = errors?.[field];
      return {
        name: field,
        error: fieldError,
        helperText: fieldError?.message,
      };
    },
    [errors]
  );

  useEffect(() => {
    dispatch(setshowOtpSection(phoneIsValid));
  }, [phoneIsValid]);

  return showOtpSection ? (
    <Fb
      justifyCenter
      alignCenter
      flex1
      sx={{
        p: {
          xs: 1,
          md: 2,
        },
        maxWidth: '100%',
      }}
    >
      <Popup
        redirectionUrl={redirectionUrl}
        text={`Код подтверждения отправлен на номер `}
        phoneNumber={+phoneNumber}
      />
    </Fb>
  ) : (
    <Fb justifyCenter alignCenter flex1 sx={{ backgroundColor: '#fff', maxWidth: '450px' }}>
      <Fb
        sx={{
          width: '100%',
          borderRadius: 5,
        }}
      >
        <Form
          style={{
            maxWidth: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <RegistrationAndLoginButtons />
          <Fb
            sx={{
              textAlign: 'center',
              flexDirection: 'column',
              marginTop: '20px',
              color: '#222',
              fontSize: {
                md: '18px',
                xs: '14px',
              },
              fontFamily: 'Golos Text',
              fontWeight: 400,
            }}
          >
            <p> Введите номер телефона,</p>
            <p> мы отправим вам СМС с кодом подтверждения</p>
          </Fb>
          <AccountRecoverModal
            showAccountRecoverModal={showAccountRecoverModal}
            toggleAccountRecoverModal={toggleAccountRecoverModal}
            recoverAccountAction={onSubmit}
          />
          <Fb justifyCenter flexDirection={'column'} sx={{ marginTop: '20px' }}>
            <Stack
              spacing={'30px'}
              sx={{
                maxWidth: '100%',
              }}
            >
              <div className="account_input_wrapper">
                <FormInput
                  resetField={resetField}
                  variant="outlined"
                  control={control}
                  name="phoneNumber"
                  placeholder="(___) - ___ - __ - __"
                  mask="(999) - 999 - 99 - 99"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        style={{ background: '#fff', padding: '18px 20px', borderRadius: '10px' }}
                        position="start"
                      >
                        +7
                      </InputAdornment>
                    ),
                  }}
                  {...validationProps('phoneNumber')}
                />
              </div>
              <Stack>
                <CustomButton
                  label="Получить код"
                  variant="filledGreen"
                  disabled={status === 'loading' || !agreeWithTermsChecked}
                  size="fullWidth"
                />

                {hideErrorMessage && (
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#db287e',
                      height: '40px',
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </Stack>
            </Stack>
            <Fb alignStart sx={{ marginTop: '10px' }}>
              <Checkbox
                checkedIcon={<CheckIconGreen />}
                checked={agreeWithTermsChecked}
                onChange={(_, checked) => setAgreeWithTermsChecked(checked)}
                sx={{ mt: '2px' }}
              />
              <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#8A8A8A' }}>
                <Link className="link" to={ROUTES.PLACINGANORDER} target="_blank">
                  Согласен с условиями Правил пользования торговой площадкой и правилами возврата
                </Link>
              </Typography>
            </Fb>
          </Fb>
        </Form>
      </Fb>
    </Fb>
  );
};

export default LoginPage;
