export default {
  DURATION_UNTIL_3_HOURS: 1,
  DURATION_TODAY: 2,
  DURATION_UNTIL_Tomorrow: 3,
  NO_DELIVERY: 4,
  DURATION_UNTIL_7_DAYS: 5,
  DURATION_UNTIL_2_DAYS: 6,
  DURATION_UNTIL_3_DAYS: 7,
};

export const PARTNER_DURATION_HOUR = 168;
export const NOT_IS_AVAILABLE = 'notIsAvailable';

export const deliveryDurationSorting = [1, 2, 3, 6, 5, 4];

export const deliveryDatesLabels = {
  '1': 'До 3-х часов',
  '2': 'Сегодня',
  '3': 'Завтра',
  '4': 'Нет курьерской доставки. Вы можете забрать товар в ПВЗ',
  '5': 'до 7 дней',
  '6': 'до 2 дней',
  '7': 'до 3 дней',
};

export const defaultDeliveryDatesHours = {
  '1': 3,
  '2': 12,
  '3': 24,
  '5': 168,
  '6': 48,
  '7': 72,
};
