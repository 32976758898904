export const NOTIFICATION_TYPES = {
  EMAIL: 1,
  SMS: 2,
};

export const styles = {
  container: {
    width: '100%',
    padding: {
      md: '20px 30px',
      sm: '20px 30px',
      xs: '24px 0px',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    background: {
      md: '#FEFFFF',
      sm: '#FEFFFF',
      xs: 'none',
    },
    border: {
      md: '1px solid #EAEAEA',
      sm: '1px solid #EAEAEA',
      xs: 'none',
    },
    borderTop: {
      md: '1px solid #EAEAEA',
      sm: '1px solid #EAEAEA',
      xs: '1px solid #EAEAEA',
    },
    borderBottom: {
      md: '1px solid #EAEAEA',
      sm: '1px solid #EAEAEA',
      xs: '1px solid #EAEAEA',
    },
    borderRadius: {
      md: '20px',
      sm: '20px',
      xs: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '28px',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    img: {
      sm: {
        width: '24px',
        height: '24px',
      },
      xs: {
        width: '20px',
        height: '20px',
      },
    },
  },
  title: {
    fontWeight: {
      md: 500,
      sm: 500,
      xs: 400,
    },
    fontSize: {
      md: '20px',
      sm: '20px',
      xs: '14px',
    },
    color: '#222',
  },
  description: {
    color: '#222',
    fontWeight: 400,
    fontFamily: 'Golos Text',
    fontSize: {
      md: '16px',
      sm: '16px',
      xs: '12px',
    },
  },
  notificationOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  notificationOption: {
    color: '#222',
    fontSize: {
      md: '16px',
      sm: '16px',
      xs: '14px',
    },
  },
  checkBoxTitle: {
    color: '#222',
    fontSize: {
      md: '16px',
      sm: '16px',
      xs: '14px',
    },
  },
};
