import { useSyncExternalStore } from 'react';

interface Props {
  type?: 'mobile' | 'smallTablet' | 'tablet' | 'desktop' | 'smallHeightDevice';
}

const useCheckScreen = ({ type }: Props): boolean | number => {
  const width = useSyncExternalStore(
    listener => {
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    },
    () => window.innerWidth
  );

  const height = useSyncExternalStore(
    listener => {
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    },
    () => window.innerHeight
  );

  if (!type) return width;

  if (type === 'mobile') {
    return width <= 600;
  } else if (type === 'smallTablet') {
    return width <= 900;
  } else if (type === 'tablet') {
    return width > 600 && width <= 1200;
  } else if (type === 'desktop') {
    return width > 1200;
  } else if (type === 'smallHeightDevice') {
    return height < 1000;
  } else {
    return false;
  }
};

export default useCheckScreen;
