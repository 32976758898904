import { useState } from 'react';

import { Typography, Stack } from '@mui/material';

import { RootState, dispatch } from 'store';
import { setshowOtpSection } from 'store/auth/LoginAndregistration';
import { useAppSelector } from 'store/hooks';
import Fb from 'components/FB';
import OtpTimer from 'components/OtpTimer';
import OtpInput from 'components/OtpInput';
import CustomButton from 'components/Button';
import formattedPhone from 'helpers/formattedPhone';

// import { icons } from './constants';

import './styles.scss';

interface Props {
  text: string;
  phoneNumber: number | string;
  handleOTPVerification: (otp: string) => void;
  type?: number;
  orderWithoutLoggingIn?: boolean;
  showPrivacyPolicy?: boolean;
  backToPhoneNumberSection?: () => void;
  handleOTPRequest?: () => void;
}

const OTPVerificationBox = ({
  text,
  phoneNumber,
  handleOTPVerification,
  backToPhoneNumberSection,
  handleOTPRequest,
  type,
  orderWithoutLoggingIn,
}: // showPrivacyPolicy = true,
Props): JSX.Element => {
  const {
    login: { loginErrorMessage },
    order: { otpErrorMessage },
    buyerRegistration: { buyerRegisterErrorMessage },
    sellerPreRegistration: { sellerRegisterErrorMessage },
  } = useAppSelector((state: RootState) => state);
  const [otp, setOtp] = useState<string>('');
  // const { checkCircle } = icons;
  // useEffect(() => {
  //   if (!orderWithoutLoggingIn && otp.length === 4) {
  //     handleOTPVerification(otp);
  //     // dispatch(setShowLoginAndRegistrationAndRegistrationModal(false));
  //   }
  // }, [otp, orderWithoutLoggingIn]);

  const onChange = (value: string) => {
    if (orderWithoutLoggingIn) {
      handleOTPVerification(value);
    }
    setOtp(value);
  };

  const handleOtpVerificationRequest = () => {
    orderWithoutLoggingIn ? handleOTPRequest() : handleOTPVerification(otp);
  };

  const handleClickShowOtpSection = () => {
    dispatch(setshowOtpSection(false));
    if (orderWithoutLoggingIn) {
      backToPhoneNumberSection();
    }
  };

  const errorMessage =
    loginErrorMessage || otpErrorMessage || buyerRegisterErrorMessage || sellerRegisterErrorMessage;

  const isOTPValid = otp.length !== 4;

  return (
    <Fb justifyCenter column className="otp-verification-box">
      <Stack spacing="20px">
        <Typography className="text_otp">
          <h1>Введите код</h1>
          <span>{text}</span>
          <span className="number">{`+7 ${formattedPhone(String(phoneNumber), 2)}`}</span>
          <p onClick={handleClickShowOtpSection}>Изменить номер</p>
        </Typography>
        <OtpInput errorMessage={errorMessage} value={otp} onChange={onChange} />
        <CustomButton
          variant="filledGreen"
          size="fullWidth"
          label="Отправить код"
          onClick={handleOtpVerificationRequest}
          disabled={isOTPValid}
        />
        <OtpTimer
          orderWithoutLoggingIn={orderWithoutLoggingIn}
          phoneNumber={phoneNumber}
          type={type}
          onChangeOtp={onChange}
        />
      </Stack>
    </Fb>
  );
};

export default OTPVerificationBox;
