import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { AppDispatch } from 'store';
import ROUTES from 'constants/routes';
import { resetStatus, useShoppingCart } from 'store/cart';

import AlertIcon from 'assets/icons/alertIcon.svg';
import FailureIcon from 'assets/icons/failed.svg';
import ArrowAlertIcon from 'assets/icons/arrowAlert.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

import './style.scss';

const { CART } = ROUTES;

const limitedCodeNumber = 739;

const AddProductInShoppingCardAlert = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [alert, setAlert] = useState<boolean>(false);

  const { addProductInShoppingCartStatus: status, limitedCode } = useShoppingCart();

  const closeAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    if (status === 'success' || limitedCode === limitedCodeNumber) {
      setAlert(true);
    } else {
      setAlert(false);
    }
    setTimeout(() => {
      setAlert(false);
      dispatch(resetStatus());
    }, 7000);
  }, [status, limitedCode]);

  const NavigateShopingCards = () => {
    navigate(CART, {
      state: {
        buyNow: false,
      },
    });
    setAlert(false);
  };

  return (
    alert && (
      <Box
        sx={{
          zIndex: 5000,
          top: '35px',
          right: 0,
          bgcolor: '#feffff',
          position: 'fixed',
          width: { xs: 'calc(100% - 16px)', sm: '492px' },
          overflow: 'hidden',
          boxShadow: '0px 0px 16px rgba(132, 139, 143, 0.2)',
          borderRadius: '8px',
          margin: { xs: '8px', lg: 0 },
          animation: { xs: 'none', lg: 'show_slide 1s ease forwards' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 16px 16px 12px',
            borderLeft: '5px solid #30b570',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              overflow: 'hidden',
            }}
          >
            <img src={limitedCode ? FailureIcon : AlertIcon} />

            {limitedCode === limitedCodeNumber ? (
              <Typography sx={{ fontSize: '14px', marginLeft: '10px', color: '#676767' }}>
                Лимит товаров в корзине превышен
              </Typography>
            ) : (
              <Typography fontSize={16} color={'#676767'} lineHeight={'normal'} ml="16px">
                Товар добавлен в корзину
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: {
                xs: 'auto',
                sm: '160px',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              component={'button'}
              onClick={NavigateShopingCards}
              className="cursor-pointer"
              sx={{
                alignItems: 'center',
                display: { xs: 'none', sm: 'flex' },
                height: '32px',
                border: '1px solid #eaeaea',
                borderRadius: '8px',
                padding: '4px 8px 4px 16px',
                bgcolor: 'transparent',
                '&:hover': {
                  color: '#292929',
                  border: '1px solid #292929',
                },
              }}
            >
              <Typography color={'#828282'} fontSize={14} fontWeight={'500'} lineHeight={'normal'}>
                В корзину
              </Typography>
              <img src={ArrowAlertIcon} alt="arrow alert icon" />
            </Box>

            <Box
              sx={{
                height: '32px',
                borderRight: '1px solid #eaeaea',
                display: { xs: 'none', sm: 'block' },
              }}
            />
            <img onClick={closeAlert} className="cursor-pointer" src={DeleteAlertIcon} />
          </Box>
        </Box>
      </Box>
    )
  );
};

export default AddProductInShoppingCardAlert;
