import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { authStorage } from 'store/storage';
import { useShoppingCart } from 'store/cart';
import { useBuyerData } from 'store/buyer/editBuyerData';
import { setShowLoginAndRegistrationAndRegistrationModal } from 'store/auth/LoginAndregistration';
import ROUTES from 'constants/routes';
import { useCurrentPath } from 'utils/useCurrentPath';

import { items, accountPersonalInfoRoute } from './constants';

import './index.scss';

const MobileFooterNavBar = () => {
  const { count } = useShoppingCart();
  const { data: { wishlistProductCount = 0 } = {} } = useBuyerData();
  const { CART, ACCOUNT_MOBILE, MOBILECATALOG, PRODUCTS_CATEGORIES, DASHBOARD, ACCOUNT, WISHLIST } =
    ROUTES;
  const isLoggedIn = !!authStorage.get().accessToken;

  const dispatch = useDispatch();

  const { path: currentPath } = useCurrentPath([
    { path: DASHBOARD },
    { path: ACCOUNT_MOBILE },
    { path: ACCOUNT },
    { path: CART },
    { path: MOBILECATALOG },
    { path: PRODUCTS_CATEGORIES },
    { path: WISHLIST },
  ]);

  const renderMobileCount = (count: number) => {
    return <div className="mobile_count">{count >= 99 ? `99+` : count}</div>;
  };

  return (
    <div className="mobile_footer" id="mobileFooter">
      {items.map(el => {
        const { path, Icon, name } = el;
        let isActive = path === currentPath;
        if (path === accountPersonalInfoRoute) {
          isActive = [ACCOUNT_MOBILE, ACCOUNT].includes(currentPath);
        }

        if (path === MOBILECATALOG) {
          isActive = [MOBILECATALOG, PRODUCTS_CATEGORIES].includes(currentPath);
        }

        return (
          <div key={path} className={classNames('icon_wrap', { isActive })}>
            {!isLoggedIn && (path === accountPersonalInfoRoute || path === WISHLIST) ? (
              <p onClick={() => dispatch(setShowLoginAndRegistrationAndRegistrationModal(true))}>
                <Icon />
              </p>
            ) : (
              <NavLink to={path}>
                <div className={classNames('mobile_cart', { isActive })}>
                  {(path === CART || path === WISHLIST) &&
                    renderMobileCount(path === CART ? count : wishlistProductCount)}
                  <Icon />
                </div>
              </NavLink>
            )}
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MobileFooterNavBar;
