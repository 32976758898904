export default {
  DASHBOARD: '/',
  SELLER_SIGN_UP: '/seller/sign-up',
  ACCOUNT: '/account/:type',
  ORDERS_ITEM: '/account/orders/:id',
  ACCOUNT_MOBILE: '/account',
  ACCOUNT_PROFILE: '/account/personal-info',
  BRANDS: '/brands',
  BRAND: '/brand/:brandID',
  CATEGORIES: '/categories',
  BRAND_CATEGORIES_LIST: '/brand/:brandID/categories/:categoryID',
  PRODUCT_BY_CATEGORIES_LIST: '/categories/:categoryID',
  BRAND_ACTUAL_OFFERS_LIST: '/actual',
  BRAND_RANDOM_OFFERS_LIST: '/interested',
  PRODUCT: '/product/:id',
  PRODUCT_REVIEWS: '/product/:id/reviews',
  CART: '/cart',
  SEARCH: '/search',
  PRODUCTS: '/products',
  WHOLESALER_PURCHASE: '/purchase',
  ORDERS: '/account/orders',
  NOT_FOUND: '/error/404',
  FOOTER: '/info/:type',
  PRODUCTS_CATEGORIES: '/category/:categoryID',
  DYNAMIC_PAGE: 'dynamic-page/:id',
  DYNAMIC_PAGES: 'dynamic-pages/:type',
  PLACINGANORDER: '/info/placing-an-order',
  MOBILECATALOG: '/catalog',
  MAX_MONEY_INFO: 'maxmoney-info',
  APRIL_PAGE_INFO: 'april-1st',
  SBERBANK: 'sberbank-info',
  REQUISITES: 'requisites-info',
  INSTALLMENT_INFO: '/installment-info',
  SELLING_RULES_INFO: '/selling-rules-info',
  FRANCHISE_INFO: '/franchise',
  WISHLIST: '/account/wishlist',
  REVIEWS: '/account/review/:id',
  SECURITY: '/security',
};
