import { Box, Modal } from '@mui/material';
import classNames from 'classnames';
import useCheckScreen from 'utils/useCheckScreen';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CloseIconWithoutBorder } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as CloseIconGreen } from 'assets/icons/closeGreen.svg';

import { ModalInterface } from './types';

import './style.scss';

const style = {
  position: { xs: 'relative', md: 'absolute' },
  top: { xs: '0', md: '50%' },
  left: { xs: '0', md: '50%' },
  width: { xs: '100%', md: 'inherit' },
  height: { xs: '100%', md: 'inherit' },
  transform: { xs: 'none', md: 'translate(-50%, -50%)' },
  minWidth: { xs: 'none', md: 400 },
  background: '#FEFFFF',
  borderRadius: '8px',
  padding: { xs: '16px', md: '32px' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:focus-visible': {
    outline: 'none',
  },

  '& .close-modal': {
    cursor: 'pointer',
  },

  '& .close-modal:not(.border_hidden)': {
    position: 'absolute',
    width: 40,
    height: 40,
    cursor: 'pointer',
    zIndex: 1,
    top: {
      sm: '-20px',
      xs: '-20px',
    },
    right: {
      sm: '-20px',
      xs: '-20px',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      '&:hover': {
        svg: {
          path: {
            stroke: '#828282',
          },
        },
      },
    },
  },

  '& .close-modal.border_hidden': {
    height: '32px',

    '& svg path': {
      stroke: '#8A8A8A',
    },
  },
};

const CustomModal = ({
  open,
  onClose,
  children,
  className,
  isFullScreen = false,
  bodyClassName,
  showCloseIcon = true,
  showClosIconGreen = false,
  closeIconWithoutBorder = false,
}: ModalInterface) => {
  const isDesktop = useCheckScreen({ type: 'desktop' });

  return (
    <Modal open={open} onClose={onClose} className={className}>
      <Box sx={style} className={bodyClassName}>
        {showCloseIcon && (
          <span
            onClick={onClose}
            className={classNames('close-modal', {
              full_screen: isFullScreen,
              border_hidden: closeIconWithoutBorder,
            })}
          >
            {showClosIconGreen && isDesktop ? (
              <CloseIconGreen />
            ) : closeIconWithoutBorder ? (
              <CloseIconWithoutBorder width={32} height={32} />
            ) : (
              <CloseIcon />
            )}
          </span>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
