import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {
  getCatalogCategoriesListUrl,
  getProductByCategoriesListUrl,
} from 'helpers/getProductByCategoriesList';
import { Category } from 'store/catalog/types';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-arrow-down.svg';

interface SubCategoryItemsProps {
  subCategory: Category;
}

const SubCategoryItem: React.FC<SubCategoryItemsProps> = ({ subCategory }) => {
  const [isMore, setIsMore] = useState<boolean>(false);
  const handleSubCategories = (id: number, name?: string) => {
    const breadCrumbsData = [
      {
        id: 1,
        to: name ? getProductByCategoriesListUrl(id) : '',
        title: subCategory.name,
      },
    ];
    if (name) {
      breadCrumbsData.push({
        id: 2,
        to: '',
        title: name,
      });
    }

    return {
      to: getProductByCategoriesListUrl(id),
      state: { breadCrumbsData },
    };
  };

  const flattenCategories = categories => {
    if (!categories) {
      return [];
    }
    return categories.reduce((newData, category) => {
      if (category?.subCategories?.length === 0) {
        newData.push(category);
      }
      if (category.subCategories && category.subCategories.data) {
        newData.push(...flattenCategories(category.subCategories.data));
      }
      return newData;
    }, []);
  };
  const newFulData = flattenCategories(subCategory.subCategories.data);

  return (
    <div key={subCategory.id} className="sub__item">
      <div>
        <Link to={getCatalogCategoriesListUrl(subCategory.id)}>
          <span className="sub__item_parent_name cursor-pointer">
            <span className="sub_parent">{subCategory.name}</span>
          </span>
        </Link>
        {newFulData
          ?.filter(e => e.isActive)
          .slice(0, isMore ? Infinity : 8)
          .map(subItem => {
            return (
              <div key={subItem.id} className="sub__item_name">
                <Link {...handleSubCategories(subItem.id, subItem.name)}>{subItem.name}</Link>
              </div>
            );
          })}
        {newFulData.length > 8 && (
          <div className={classNames('category_isMore', { isMore })}>
            <p onClick={() => setIsMore(prev => !prev)}>
              {isMore ? 'Скрыть' : 'Развернуть'} <ArrowDown />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SubCategoryItem);
