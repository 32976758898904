import { DYNAMIC_TYPES } from 'constants/dynamicPages';

import routes from 'constants/routes';

import InstagramIcon from 'assets/icons/instagram.svg';
import VKIcon from 'assets/icons/vkontakte.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import AddressMarkIcon from 'assets/icons/address-marker.svg';
import MailIcon from 'assets/icons/mail.svg';
import ArrowPrevIcon from 'assets/icons/vector-prev.svg';
import ArrowNextIcon from 'assets/icons/vector-next.svg';

export interface FooterLinkInterface {
  label: string;
  link: string;
}

export const footerLinks = {
  forBuyers: [
    {
      label: 'Доставка и оплата',
      link: 'info/shipping-payment',
    },

    {
      label: 'Полезная информация',
      link: 'info/secure-payment',
    },
    {
      label: 'Оформление заказа',
      link: 'info/placing-an-order',
    },
    {
      label: 'Бонусная программа',
      link: '/maxmoney-info',
    },
    {
      label: 'Правила продажи товара на сайте',
      link: routes.SELLING_RULES_INFO,
    },
    {
      label: 'Положение о персональных данных',
      link: 'info/regulation-personal-data',
    },
  ],
  // forStores: [
  //   // {
  //   //   label: 'Как продавать на МаксМаркет?',
  //   //   link: 'info/how-to-sell',
  //   // },
  // ],
  forPartners: [
    {
      label: 'Договор оферты',
      link: 'info/contract-offer',
    },
    {
      label: 'Оптовая закупка',
      link: 'info/wholesaler-purchase',
    },
    {
      label: 'Франшиза',
      link: routes.FRANCHISE_INFO,
    },
    {
      label: 'Как продавать на МаксМаркет?',
      link: 'info/how-to-sell',
    },
  ],
  newsCompanies: [
    {
      label: 'Новости компании',
      link: `dynamic-pages/${DYNAMIC_TYPES.NEWS}`,
    },
    {
      label: 'МаксМаркет рекомендует',
      link: `dynamic-pages/${DYNAMIC_TYPES.RECOMMENDS}`,
    },
  ],
};

export const footerIcons = {
  InstagramIcon,
  VKIcon,
  PhoneIcon,
  AddressMarkIcon,
  MailIcon,
  ArrowNextIcon,
  ArrowPrevIcon,
};
