import axios from 'axios';
import reverse from 'lodash/reverse';

import { REACT_APP_YANDEX_MAP_KEY } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';

export const defaultCity = 'Москва';
export const defaultPostalCode = '109012';

const getAddressName = async (coords, type = '') => {
  const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
    await axios.get(
      `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${coords
        .reverse()
        .join(',')}`
    );
  const { GeoObject: { metaDataProperty: { GeocoderMetaData: { Address = {} } = {} } = {} } = {} } =
    featureMember[0] || [];
  const { formatted = '', Components } = Address;
  const returedData = {
    city: defaultCity,
    address: formatted,
  } as { city: string; address: string; countryCode: string };

  if (type === 'city') {
    const { country_code } = Address;

    returedData.countryCode = country_code;

    if (country_code === 'RU') {
      const locality = Components.find(({ kind }) => kind === 'locality');
      const area = Components.find(({ kind }) => kind === 'area');
      const province = Components.find(({ kind }) => kind === 'province');

      returedData.city = locality?.name || area?.name || province?.name || defaultCity;
      return returedData;
    } else {
      returedData.city = defaultCity;
      returedData.address = defaultCity;
      return returedData;
    }
  }
  return formatted;
};

export const getAddressDetails = async (address, showDetails = false) => {
  const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
    await axios.get(
      `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${address}`
    );

  const {
    GeoObject: {
      metaDataProperty: {
        GeocoderMetaData: {
          Address: { Components, postal_code = '', country_code, formatted },
        },
      },
      Point: { pos = '' } = {},
    },
  } = featureMember[0] || {};

  if (country_code === 'RU') {
    let city =
      Components.find(({ kind }) => kind === 'locality')?.name ||
      Components.find(({ kind }) => kind === 'province')?.name ||
      '';

    if (!city) {
      return;
    }

    if (city === 'Центральный федеральный округ') {
      city = 'Москва';
    } else if (city === 'Северо-Западный федеральный округ') {
      city = 'Санкт-Петербург';
    }

    const [longitude, latitude] = pos.split(' ');
    const returedData: any = {
      latitude,
      longitude,
      city,
      address: formatted,
    };

    if (showDetails) {
      const street = Components.find(({ kind }) => kind === 'street')?.name || '';
      const house = Components.find(({ kind }) => kind === 'house')?.name || '';

      returedData.street = street;
      returedData.building = house;
      returedData.zipCode = postal_code;
    }
    return returedData;
  }
};

export const getCityFromGeoSuggest = async address => {
  const addressData = {
    newSelectedCity: '',
    newSelectedCityLatLng: [],
  };

  if (address) {
    const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
      await axios.get(
        `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${address}`
      );

    if (featureMember?.length) {
      const addressComponents =
        featureMember[0]?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address?.Components;
      const addressPos = featureMember[0]?.GeoObject?.Point?.pos;

      if (addressComponents) {
        const country = addressComponents.find(({ kind = '' }) => kind === 'country')?.name;
        if (country !== 'Россия') {
          addressData.newSelectedCity = 'Москва';
          addressData.newSelectedCityLatLng = [MOSCOW_LAT, MOSCOW_LNG];
          return addressData;
        }
        let newCity = addressComponents.find(({ kind = '' }) => kind === 'locality')?.name;

        if (!newCity) {
          newCity = addressComponents.find(({ kind = '' }) => kind === 'province')?.name;
        }

        if (newCity === 'Центральный федеральный округ') {
          newCity = 'Москва';
        } else if (newCity === 'Северо-Западный федеральный округ') {
          newCity = 'Санкт-Петербург';
        }
        addressData.newSelectedCity = newCity;
      }

      if (addressPos) {
        addressData.newSelectedCityLatLng = reverse(addressPos.split(' '));
      }
    }
  }
  return addressData;
};

export default getAddressName;

export const getPostalCode = async coords => {
  const [lat, long] = coords;
  try {
    const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
      await axios.get(
        `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${long},${lat}&kind=house&results=50`
      );
    let postalCode = '';
    for (let i = 0; i < featureMember.length; i++) {
      const {
        GeoObject: { metaDataProperty: { GeocoderMetaData: { Address = {} } = {} } = {} } = {},
      } = featureMember[i];
      const { postal_code } = Address;
      if (postal_code) {
        postalCode = postal_code;
        break;
      }
    }
    return postalCode;
  } catch (error) {
    return '';
  }
};
