import { useDispatch } from 'react-redux';

import {
  setShowLoginAndRegistration,
  useLoginAndRegistration,
} from 'store/auth/LoginAndregistration';
import Fb from 'components/FB';

import './styles.scss';

const RegistrationAndLoginButtons = () => {
  const { showLoginAndRegistration } = useLoginAndRegistration();
  const dispatch = useDispatch();

  const toggleLogin = () => {
    dispatch(setShowLoginAndRegistration(!showLoginAndRegistration));
  };
  return (
    <Fb alignCenter justifyCenter className="registration-login-buts-wrap">
      <span onClick={toggleLogin} className={showLoginAndRegistration ? 'active' : 'inactive'}>
        Вход
      </span>
      <span className="separate"> | </span>
      <span onClick={toggleLogin} className={!showLoginAndRegistration ? 'active' : 'inactive'}>
        Регистрация
      </span>
    </Fb>
  );
};

export default RegistrationAndLoginButtons;
