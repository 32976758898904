import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { read } from 'utils/api';
import {
  REACT_APP_BUYER_URL,
  REACT_APP_BUYER_URL_V2,
  REACT_APP_PRODUCTS_SEARCH_API,
} from 'constants/config';
import { RootState } from 'store';
import { TAG_ID_FILTER_NAME, TAG_ID_FILTER_NAME_V2 } from 'constants/filtration';
import { useAppSelector } from 'store/hooks';
import { SearchBy } from 'store/products/types';
import { authStorage } from 'store/storage';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import formatFilterObject from 'helpers/formatFilterObject';
import { getJsonParse } from 'helpers/json';

import {
  ActiveTagsRequestParams,
  CategoriesParamsInterface,
  ProductListState,
  SearchProductsInterface,
} from './types';

let getProductsAbortController: AbortController | null = null;

let getFiltersAbortController: AbortController | null = null;

const getFilters = async (
  {
    keyword,
    categoryId,
    searchBy,
    page,
    elasticEnabled,
    outOfCheck,
    postalCode,
    bannerToken,
    isActual,
    enableDurationPostalCode,
    enableFiltrationV2,
    filteringObject,
    limit,
  }: CategoriesParamsInterface,
  { getState }
) => {
  if (getFiltersAbortController) {
    getFiltersAbortController.abort();
  }
  const {
    products: { partnerDeliveryData, storageDeliveryDurations, filters },
  } = getState();

  const { meta: { currentPage = null, lastPage = null } = {} } = filters ?? {};

  if (page !== 1 && currentPage && lastPage && currentPage === lastPage) {
    return {};
  }

  let url = `${REACT_APP_BUYER_URL}/category-filters`;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  let params: any = {
    page,
    limit,
    enhanced: elasticEnabled,
  };
  if (outOfCheck) {
    params.showNotExistingProducts = true;
  }
  if (keyword) {
    params.keyword = keyword;
  }
  if (bannerToken) {
    params.bannerToken = bannerToken;
  }
  if (categoryId && (searchBy === SearchBy.CategoryId || searchBy === SearchBy.Auto)) {
    if (enableFiltrationV2) {
      params.categoryIds = categoryId;
    } else {
      params.categoryId = categoryId;
    }
  }
  if (enableFiltrationV2) {
    url = `${REACT_APP_BUYER_URL_V2}/filters`;

    if (isActual) {
      params.isActual = isActual;
    }

    if (filteringObject) {
      const newFilters = formatFilterObject(filteringObject);

      params = {
        ...params,
        ...newFilters,
      };
    }

    if (!enableDurationPostalCode) {
      params.withoutPostalCode = true;
    }

    if (params.brandId) {
      params.brandIds = params.brandId;
      delete params.brandId;
    }

    if (params.tagId) {
      params.tagIds = params.tagId;
      delete params.tagId;
    }
  }

  const { courierDeliveryDuration, deliveryDuration } = params;
  const deliveryDurations = deliveryDuration?.split('|~') || [];

  if (courierDeliveryDuration && !deliveryDurations?.includes('-3')) {
    deliveryDurations.push(-3);
    params.deliveryDuration = deliveryDurations.join('|~');
    delete params.courierDeliveryDuration;
  }

  if (
    params.deliveryDuration &&
    ((storageDeliveryDurations.includes(partnerDeliveryData[0]) &&
      params.deliveryDuration?.toString().includes(partnerDeliveryData[0])) ||
      params.deliveryDuration?.toString().includes('|~'))
  ) {
    delete params.deliveryDuration;
  }

  if (enableDurationPostalCode) {
    params.postalCode = selectedPostalCode;
  }

  getFiltersAbortController = new AbortController();

  return await read(url, {
    params,
    signal: getFiltersAbortController.signal,
  });
};
export const getFiltersThunk = createAsyncThunk('productsList/getFilters', getFilters);

interface MaxPriceRequest {
  categoryId?: number;
  brandId?: number;
  keyword?: string;
  searchBy: string;
  bannerToken: string;
  outOfCheck?: boolean;
  isActual?: number;
  elasticEnabled?: boolean;
  enableDurationPostalCode: boolean;
  enableFiltrationV2: boolean;
  postalCode: string;
  filteringObject: any;
}

export const getProductsSuggestions = createAsyncThunk(
  'search/keyword',
  async ({ keyword }: SearchProductsInterface) =>
    await read(
      `${REACT_APP_PRODUCTS_SEARCH_API}?req=${keyword}&page=1&pageSize=100&clid=maxmarketru&cat=all`
    )
);

const getProductsMaxPrice = async (
  {
    categoryId,
    brandId,
    keyword,
    searchBy,
    bannerToken,
    outOfCheck,
    elasticEnabled,
    enableDurationPostalCode,
    enableFiltrationV2,
    isActual,
    postalCode,
    filteringObject,
  }: MaxPriceRequest,
  { getState }
) => {
  const {
    products: { partnerDeliveryData, storageDeliveryDurations },
  } = getState();

  let url = REACT_APP_BUYER_URL;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;
  let params: any = {};
  if (outOfCheck) {
    params.showNotExistingProducts = true;
  }
  if (categoryId && (searchBy === SearchBy.CategoryId || searchBy === SearchBy.Auto)) {
    params.categoryIds = categoryId;
  }
  if (brandId) {
    params.brandIds = brandId;
  }
  if (bannerToken) {
    params.bannerToken = bannerToken;
  }
  if (keyword) {
    params.keyword = keyword;
  }
  if (enableFiltrationV2) {
    url = REACT_APP_BUYER_URL_V2;

    if (isActual) {
      params.isActual = isActual;
    }

    if (filteringObject) {
      const newFilters = formatFilterObject(filteringObject);

      params = {
        ...params,
        ...newFilters,
      };
    }

    if (params.brandId) {
      params.brandIds = params.brandId;
      delete params.brandId;
    }

    if (params.tagId) {
      params.tagIds = params.tagId;
      delete params.tagId;
    }
  }

  const { courierDeliveryDuration, deliveryDuration } = params;
  const deliveryDurations = deliveryDuration?.split('|~') || [];

  if (courierDeliveryDuration && !deliveryDurations?.includes('-3')) {
    deliveryDurations.push(-3);
    params.deliveryDuration = deliveryDurations.join('|~');
    delete params.courierDeliveryDuration;
  }

  if (
    params.deliveryDuration &&
    ((storageDeliveryDurations.includes(partnerDeliveryData[0]) &&
      params.deliveryDuration?.toString().includes(partnerDeliveryData[0])) ||
      params.deliveryDuration?.toString().includes('|~'))
  ) {
    delete params.deliveryDuration;
  }

  if (elasticEnabled) {
    params.enhanced = true;
  }

  if (enableDurationPostalCode) {
    params.postalCode = selectedPostalCode;
  } else if (enableFiltrationV2) {
    params.withoutPostalCode = true;
  }

  return await read(`${url}/products/max-price`, {
    params,
  });
};

const getStorageDeliveryDurations = async ({
  postalCode,
  brandID,
  categoryID,
  bannerToken = undefined,
  isActual,
  filteringObject,
}: any) => {
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  const params = {
    postalCode: selectedPostalCode,
    brandIds: brandID,
    bannerToken,
    categoryIds: categoryID,
    ...filteringObject,
  };

  if (isActual) {
    params.isActual = isActual;
  }

  return await read(`${REACT_APP_BUYER_URL_V2}/storage-delivery-durations`, {
    params,
  });
};

export const getStorageDeliveryDurationsThunk = createAsyncThunk(
  'storageDeliveryDurations/get',
  getStorageDeliveryDurations
);

const getActiveTags = async (params: ActiveTagsRequestParams, { getState }) => {
  const {
    products: {
      partnerDeliveryData,
      storageDeliveryDurations,
      activeTags: { data: oldActiveTags },
    },
  } = getState();

  const {
    enableDurationPostalCode,
    enableFiltrationV2 = false,
    filteringObject,
    postalCode,
    categoryId,
  } = params;

  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  let url = REACT_APP_BUYER_URL;

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.categoryId = undefined;
    params.categoryIds = categoryId;
    params.postalCode = selectedPostalCode;
  }

  let selectedTags = [];
  if (enableFiltrationV2 && filteringObject) {
    url = REACT_APP_BUYER_URL_V2;

    const tagIdFilterName = enableDurationPostalCode ? TAG_ID_FILTER_NAME_V2 : TAG_ID_FILTER_NAME;

    const tagIds = filteringObject[tagIdFilterName];
    selectedTags = tagIds ? tagIds.split(',') : [];

    const newFilters = formatFilterObject(filteringObject);
    delete params.filteringObject;

    params = {
      ...params,
      ...newFilters,
    };

    if (params[tagIdFilterName]) {
      delete params[tagIdFilterName];
    }

    if (params.categoryId) {
      params.categoryIds = categoryId;
      delete params.categoryId;
    }

    if (params.brandId) {
      params.brandIds = params.brandId;
      delete params.brandId;
    }

    if (!enableDurationPostalCode) {
      params.withoutPostalCode = true;
    }
  }

  const { courierDeliveryDuration, deliveryDuration } = params;
  const deliveryDurations = deliveryDuration?.split('|~') || [];

  if (courierDeliveryDuration && !deliveryDurations?.includes('-3')) {
    deliveryDurations.push(-3);
    params.deliveryDuration = deliveryDurations.join('|~');
    delete params.courierDeliveryDuration;
  }

  if (
    params.deliveryDuration &&
    ((storageDeliveryDurations.includes(partnerDeliveryData[0]) &&
      params.deliveryDuration?.toString().includes(partnerDeliveryData[0])) ||
      params.deliveryDuration?.toString().includes('|~'))
  ) {
    delete params.deliveryDuration;
  }

  params.enableDurationPostalCode = undefined;
  params.enableFiltrationV2 = undefined;
  params[TAG_ID_FILTER_NAME_V2] = undefined;
  params[TAG_ID_FILTER_NAME] = undefined;

  const response = await read(`${url}/active-tags`, { params });

  if (enableFiltrationV2 && selectedTags.length) {
    const oldSelectedTags = oldActiveTags.filter(({ id }) => selectedTags.includes(`${id}`));
    return { ...response, oldSelectedTags };
  }

  return response;
};

export const getActiveTagsThunk = createAsyncThunk('activeTags/get', getActiveTags);

export const getProductsMaxPriceThunk = createAsyncThunk('products/maxPrice', getProductsMaxPrice);

export const getProductListAPI = async (
  { params, filteringObject, postalCode, enableDurationPostalCode, enableFiltrationV2 }: any,
  { getState }
) => {
  const {
    products: { partnerDeliveryData, storageDeliveryDurations },
  } = getState();

  if (getProductsAbortController) {
    getProductsAbortController.abort();
  }

  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  let url = REACT_APP_BUYER_URL;
  const { keyword, order, elasticEnabled = false } = params;
  let filters = filteringObject;
  if (elasticEnabled) {
    url = REACT_APP_BUYER_URL_V2;
    if (keyword) {
      params.q = keyword;
      delete params.keyword;
    }
    if (order) {
      params.order = order.replace('updated_at', 'updatedAt');
    }
  }

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = selectedPostalCode;
    filters = formatFilterObject(filteringObject);
  } else if (enableFiltrationV2) {
    params.withoutPostalCode = true;
  }

  if (filters) {
    params = {
      ...params,
      ...filters,
    };
  }

  const { courierDeliveryDuration, deliveryDuration } = params;
  const deliveryDurations = deliveryDuration?.split('|~') || [];

  if (courierDeliveryDuration && !deliveryDurations?.includes('-3')) {
    deliveryDurations.push(-3);
    params.deliveryDuration = deliveryDurations.join('|~');
    delete params.courierDeliveryDuration;
  }

  if (
    params.deliveryDuration &&
    ((storageDeliveryDurations.includes(partnerDeliveryData[0]) &&
      params.deliveryDuration?.toString().includes(partnerDeliveryData[0])) ||
      params.deliveryDuration?.toString().includes('|~'))
  ) {
    delete params.deliveryDuration;
  }

  getProductsAbortController = new AbortController();

  const response = await read(`${url}/products`, {
    params,
    signal: getProductsAbortController.signal,
  });

  try {
    const { data = [] } = response || {};
    const isLoggedIn = !!authStorage.get().accessToken;
    if ((elasticEnabled || enableDurationPostalCode) && isLoggedIn && data?.length) {
      const productsIds = data.map(e => e.id).toString();

      const wishlistProductsResponse = await read(
        `${REACT_APP_BUYER_URL}/wishlist-products/${productsIds}`
      );

      const wishlistProducts = wishlistProductsResponse?.data || [];
      const newData = data.reduce((acc, data) => {
        const WishlistId = wishlistProducts.find(
          wishlistProducts => wishlistProducts.productId === data.id
        );
        acc.push({
          ...data,
          wishlistProductId: WishlistId ? WishlistId.id : data.wishlistProductId,
        });
        return acc;
      }, []);
      response.data = newData;
    }
  } catch (err) {
    console.error(err);
  }
  return response;
};

export const readProductList = createAsyncThunk('productList/get', getProductListAPI);

const initialState: ProductListState = {
  data: [],
  meta: {},
  minPrice: 0,
  maxPrice: 0,
  getProductsErrorCode: null,
  categoryPageSearchBy: SearchBy.Auto,
  status: 'idle',
  filters: {
    data: [],
    meta: {},
    getFiltersLoading: false,
  },
  activeTags: { data: [], meta: {}, status: 'idle' },
  filtrationBrands: { data: [], meta: {}, status: 'idle' },
  resetedRangeField: {
    field: '',
    values: [],
  },
  productSuggestions: [],
  formattedFilters: [],
  filteringDataObject: {},
  savedFilteringDataObjectForLater: {},
  labels: [],
  savedLabelsForLater: [],
  storageAndPartnerDurationsMerged: [],
  storageDeliveryDurations: [],
  partnerDeliveryData: [],
  courierDeliveryData: [],
};

const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSearchCategoryId: (state: ProductListState, action: PayloadAction<SearchBy>) => {
      state.categoryPageSearchBy = action.payload;
    },
    removeFromWishlistInCategoryProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInCategoryProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    resetBrandProducts: (state: ProductListState) => {
      const { data, meta, status } = initialState;
      return {
        ...state,
        data,
        meta,
        status,
      };
    },
    resetProductsSuggestions: (state: ProductListState) => {
      return {
        ...state,
        productSuggestions: [],
      };
    },
    setFilteringObject: (state: ProductListState, action) => {
      const { filteringData, callback } = action.payload;
      callback && callback();
      state.filteringDataObject = filteringData;
    },
    setSavedFilteringDataObjectForLater: (state: ProductListState, action) => {
      state.savedFilteringDataObjectForLater = action.payload;
    },
    setLabels: (state: ProductListState, action) => {
      state.labels = action.payload;
    },
    setSavedLabelsForLater: (state: ProductListState, action) => {
      state.savedLabelsForLater = action.payload;
    },
    removeFromWishlistInAllProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInAllProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));

      return {
        ...state,
        data,
      };
    },
    resetProducts: (state: ProductListState) => {
      const { meta, data, status, getProductsErrorCode } = initialState;
      return {
        ...state,
        meta,
        data,
        status,
        getProductsErrorCode,
      };
    },
    resetFilteredBrands: (state: ProductListState) => {
      return {
        ...state,
        filtrationBrands: initialState.filtrationBrands,
      };
    },
    resetFilters: (state: ProductListState) => {
      return {
        ...state,
        formattedFilters: initialState.formattedFilters,
        filters: initialState.filters,
      };
    },
    resetActiveTags: (state: ProductListState) => {
      const { activeTags } = initialState;
      return {
        ...state,
        activeTags,
      };
    },
    resetStorageDeliveryDurations: (state: ProductListState) => {
      const { storageDeliveryDurations, partnerDeliveryData, storageAndPartnerDurationsMerged } =
        initialState;
      return {
        ...state,
        storageDeliveryDurations,
        partnerDeliveryData,
        storageAndPartnerDurationsMerged,
      };
    },
    setFormattedFilters: (state: ProductListState, action) => {
      const { data } = action.payload;
      return {
        ...state,
        formattedFilters: data,
      };
    },
    resetRangeValue: (state: ProductListState, action) => {
      return {
        ...state,
        resetedRangeField: action.payload,
      };
    },
    resetMaxPrice: (state: ProductListState) => {
      return {
        ...state,
        minPrice: initialState.minPrice,
        maxPrice: initialState.maxPrice,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProductsMaxPriceThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { maxPrice, minPrice = 0 } = action.payload;
        return {
          ...state,
          minPrice,
          maxPrice,
        };
      })
      .addCase(getFiltersThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
          filters: {
            ...state.filters,
            getFiltersLoading: true,
          },
        };
      })
      .addCase(getFiltersThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const {
          meta: {
            arg: { page, enableFiltrationV2 = false },
          },
          payload: { data: newFilters = [], meta = {} },
        } = action;

        if (!newFilters?.length) {
          return {
            ...state,
            filters: {
              ...state.filters,
              getFiltersLoading: false,
            },
          };
        }

        const filters = cloneDeep(state.filters.data);

        const updatedFilters = newFilters.map(filterObject => {
          // In V1 filters is stringified array
          const filtersObj =
            typeof filterObject.filters === 'string'
              ? getJsonParse(filterObject.filters)
              : filterObject.filters;
          const updatedFilterItems = filtersObj.map((value: any) => ({
            value,
            disabled: false,
          }));

          // Return new filters list if V2 is disabled or old filters list is empty
          if (!enableFiltrationV2 || !filters.length) {
            return { ...filterObject, filters: updatedFilterItems };
          }

          // const isFilterItemApplied =
          //   Object.keys(filteringObject)?.length &&
          //   !Object.keys(filteringObject).includes(filterObject.name);
          // const oldFilterWithSameName = filters.find(({ name }) => name === filterObject.name);
          //
          // if (oldFilterWithSameName) {
          //   oldFilterWithSameName.filters.forEach(oldFilterItem => {
          //     const isCurrentlyExistingFilterOption = filterObject.filters.includes(
          //       oldFilterItem.value
          //     );
          //
          //     if (!isCurrentlyExistingFilterOption) {
          //       updatedFilterItems.push({
          //         value: oldFilterItem.value,
          //         disabled: isFilterItemApplied,
          //       });
          //     }
          //   });
          // }

          return { ...filterObject, filters: updatedFilterItems };
        });

        return {
          ...state,
          filters: {
            data: page === 1 ? updatedFilters : [...state.filters.data, ...updatedFilters],
            meta,
            getFiltersLoading: false,
          },
        };
      })
      .addCase(getFiltersThunk.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          filters: {
            ...state.filters,
            getFiltersLoading: false,
          },
        };
      })
      .addCase(getActiveTagsThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { data, meta, oldSelectedTags = [] } = action.payload;

        let newTags = [];

        if (meta.currentPage === 1) {
          newTags = [...oldSelectedTags, ...data];
        } else {
          newTags = [...state.activeTags.data, ...data];
        }

        newTags = newTags.filter(
          (value, index, self) => index === self.findIndex(t => t.id === value.id)
        );

        return {
          ...state,
          activeTags: {
            data: newTags,
            meta,
            status: 'success',
          },
        };
      })
      .addCase(
        getStorageDeliveryDurationsThunk.fulfilled.type,
        (state: ProductListState, action: any) => {
          const { data, partnerDeliveryData, courierDeliveryData } = action.payload;

          const storageAndPartnerDurationsMerged = [
            ...new Set([...data, ...partnerDeliveryData].sort((a, b) => Math.abs(a) - Math.abs(b))),
          ];
          const sortedCourierDeliveryData = courierDeliveryData.sort(
            (a, b) => Math.abs(a) - Math.abs(b)
          );
          return {
            ...state,
            storageAndPartnerDurationsMerged,
            storageDeliveryDurations: data,
            partnerDeliveryData,
            courierDeliveryData: sortedCourierDeliveryData,
          };
        }
      )
      .addCase(getActiveTagsThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
          activeTags: {
            ...state.activeTags,
            status: 'loading',
          },
        };
      })
      .addCase(getActiveTagsThunk.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          activeTags: {
            ...state.activeTags,
            status: 'failed',
          },
        };
      })
      .addCase(getProductsSuggestions.fulfilled.type, (state, action: any) => {
        const {
          payload: { offers },
        } = action;

        return {
          ...state,
          productSuggestions: offers,
        };
      })
      .addCase(readProductList.fulfilled.type, (state: ProductListState, action: any) => {
        const { data, meta, additionalData } = action.payload;

        return {
          ...state,
          status: 'success',
          data,
          additionalData,
          meta,
        };
      })
      .addCase(readProductList.pending.type, (state: ProductListState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(readProductList.rejected.type, (state: ProductListState, action: any) => {
        const {
          meta: { aborted },
        } = action;
        const { data, meta } = initialState;
        if (aborted) {
          return {
            ...state,
            data,
            meta,
          };
        }
        return {
          ...state,
          data,
          meta,
          status: 'failed',
        };
      });
  },
});

export const useProductListData = (): ProductListState =>
  useAppSelector((state: RootState) => state.products);

export default ProductsSlice.reducer;
export const {
  changWishlistProductIdInCategoryProducts,
  removeFromWishlistInCategoryProducts,
  resetRangeValue,
  resetProducts,
  resetMaxPrice,
  changWishlistProductIdInAllProducts,
  removeFromWishlistInAllProducts,
  resetActiveTags,
  setFilteringObject,
  setLabels,
  setSavedLabelsForLater,
  setFormattedFilters,
  resetProductsSuggestions,
  setSavedFilteringDataObjectForLater,
  setSearchCategoryId,
  resetBrandProducts,
  resetFilteredBrands,
  resetStorageDeliveryDurations,
} = ProductsSlice.actions;
