import { useDispatch } from 'react-redux';

import {
  setshowOtpSection,
  setShowLoginAndRegistration,
  setShowLoginAndRegistrationAndRegistrationModal,
  useLoginAndRegistration,
} from 'store/auth/LoginAndregistration';
import Modal from 'components/Modal';
import RegistrationPage from '../Registration/Registration';
import LoginPage from '../Login/Login';

export const LoginRegistration = () => {
  const dispatch = useDispatch();
  const { showLoginAndRegistrationAndRegistratinModal, showLoginAndRegistration } =
    useLoginAndRegistration();

  const onClose = () => {
    dispatch(setShowLoginAndRegistrationAndRegistrationModal(false));
    dispatch(setShowLoginAndRegistration(true));
    dispatch(setshowOtpSection(false));
  };
  return (
    <Modal
      showClosIconGreen={true}
      className="login_modal_wrapper"
      bodyClassName="login_and_registration"
      open={showLoginAndRegistrationAndRegistratinModal}
      onClose={onClose}
    >
      {showLoginAndRegistration ? <LoginPage /> : <RegistrationPage />}
    </Modal>
  );
};

export default LoginRegistration;
