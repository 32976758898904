import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';

import { AppBar, Box, FormControl, Grid, IconButton, Link, Typography } from '@mui/material';
import classNames from 'classnames';

import { readUserDataThunk, useBuyerData } from 'store/buyer/editBuyerData';
import { readUserData as readSellerData, useSellerData } from 'store/account/seller';
import { authStorage } from 'store/storage';
import {
  getLoggedOutUserCartCountThunk,
  getShoppingCartProductIdsThunk,
  useShoppingCart,
} from 'store/cart';

// import { setSearchCategoryId, useProductListData } from 'store/brandCategory';
// import { SearchBy } from 'store/brandCategory/types';
import Form from 'components/Form';
import Fb from 'components/FB';
import ErrorBoundary from 'components/ErrorBoundary';
import AddProductInShoppingCardAlert from 'components/AddProductInShoppingCardAlert/AddProductInShoppingCardAlert';
import CreateProductReviewAlert from 'components/CreateProductReviewAlert/CreateProductReviewAlert';
import CheckDeliveryDatesAlert from 'components/CheckDeliveryDatesAlert';
import CheckInstallmentLimitAlert from 'components/CheckInstallmentLimitAlert';
import CheckShoppingCartAlert from 'components/CheckShoppingCartAlert';
// import { getProductByCategoriesListUrl } from 'helpers/getProductByCategoriesList';
import userTypes from 'constants/userTypes';
import ROUTES from 'constants/routes';
import useFiltration from 'utils/useFiltration';
import useCheckScreen from 'utils/useCheckScreen';
import { recordAnalyticsEvent } from 'helpers/analyticsEvents';

// import { useCurrentPath } from 'utils/useCurrentPath';

import Catalog from './Catalog';
import GeolocationMap from './GeolocationMap';
import ProfileMenu from './ProfileMenu';
import SearchAutocomplete from './SearchAutocomplete';

import { ReactComponent as Heart } from 'assets/icons/heartHeader.svg';
import { ReactComponent as MaxMarketHeaderLogo } from 'assets/icons/maxmarket-header-logo.svg';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/shoppingCartHeader.svg';
// import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
// import { ReactComponent as PhoneIcon } from 'assets/icons/headerPhone.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';

import './index.scss';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const isMobile = useCheckScreen({ type: 'mobile' });

  const [headerBoxShadow, setHeaderBoxShadow] = useState<boolean>(false);
  const [keyword, setSearchKeyword] = useState('');
  const {
    data: {
      wishlistProductCount = 0,
      avatar: buyerAvatar = '',
      id: userId = '',
      email,
      fullName,
      phoneNumber,
    } = {},
  } = useBuyerData();

  const enableDurationPostalCode = useFlag('enable_duration_postal_code');

  const { clearFiltration } = useFiltration();
  const { count } = useShoppingCart();

  const { DASHBOARD } = ROUTES;

  const localStorageCartProducts = localStorage.getItem('cartProducts');

  // const { path: currentPath, params: currentPathParams } = useCurrentPath([
  //   { path: PRODUCT_BY_CATEGORIES_LIST },
  // ]);

  // const { categoryPageSearchBy } = useProductListData();
  const {
    data: { shop, avatar: sellerAvatar = '' },
  } = useSellerData();

  // const { categoryID } = currentPathParams;

  const anchorRef = useRef<HTMLDivElement>(null);
  const updateUnleashContext = useUnleashContext();
  const searchParams = new URLSearchParams(search);

  const { logo: shopLogo = '' } = shop || {};
  const { buyer } = userTypes;
  const { userType } = authStorage.get();
  const isLoggedIn = !!authStorage.get().accessToken;
  const isBuyerType = userType === buyer;

  const clearDataLayerFlagEnabled = useFlag('enable_datalayer_clear');
  const enableRetailRocketFlagEnabled = useFlag('enable_retailrocket_integration');

  // useEffect(() => {
  //   return () => {
  //     dispatch(setSearchCategoryId(SearchBy.Auto));
  //   };
  // }, [currentPath]);

  useEffect(() => {
    if (userId) {
      dispatch(getShoppingCartProductIdsThunk());

      recordAnalyticsEvent(
        'setCustomer',
        { email, phoneNumber, fullName, userId },
        clearDataLayerFlagEnabled,
        enableRetailRocketFlagEnabled,
        false
      );
    }
  }, [userId]);

  useEffect(() => {
    if (!isLoggedIn && localStorageCartProducts && pathname !== ROUTES.CART) {
      let cartProducts: any = localStorageCartProducts;
      try {
        if (cartProducts) {
          cartProducts = JSON.parse(cartProducts);
          cartProducts = Object.values(cartProducts) || [];
          const products = [];
          cartProducts.forEach(data => {
            data.forEach(({ id, count }) => {
              products.push({
                id,
                count,
              });
            });
          });
          if (products.length) {
            dispatch(getLoggedOutUserCartCountThunk({ products }));
          }
        }
      } catch (error) {
        localStorage.removeItem('cartProducts');
      }
    }
  }, [isLoggedIn, pathname, localStorageCartProducts]);

  useEffect(() => {
    const changeBoxShadow = () => {
      if (window.scrollY) {
        setHeaderBoxShadow(true);
      } else {
        setHeaderBoxShadow(false);
      }
    };
    window.addEventListener('scroll', changeBoxShadow);
    return () => {
      window.removeEventListener('scroll', changeBoxShadow);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const newAPIToken = params.get('new-api-token');
    const userIDValue = newAPIToken === 'bWF4bWFya2V0LXRlYW0=' ? 'new-api' : userId;

    updateUnleashContext({ userId: userIDValue });
  }, [userId]);

  useEffect(() => {
    let readUserDataRequest = null;
    if (isLoggedIn) {
      if (isBuyerType) {
        readUserDataRequest = dispatch(readUserDataThunk(enableDurationPostalCode));
      } else {
        dispatch(readSellerData());
      }
      return () => {
        if (readUserDataRequest) {
          readUserDataRequest?.abort();
        }
      };
    }
  }, [isLoggedIn, pathname, enableDurationPostalCode]);

  const handleRedirectToWishlist = () => {
    navigate('/account/wishlist');
  };

  const handleSearchProducts = () => {
    // if (keyword && categoryPageSearchBy === SearchBy.Auto) {
    //   dispatch(setSearchCategoryId(SearchBy.All));
    // }
    // const page =
    //   currentPath === PRODUCT_BY_CATEGORIES_LIST && categoryID
    //     ? getProductByCategoriesListUrl(categoryID)
    //     : undefined;

    if (searchParams.has('q')) {
      searchParams.delete('q');
    }

    if (searchParams.has('bannerToken')) {
      searchParams.delete('bannerToken');
    }

    searchParams.set('page', '1');
    keyword.trim() && searchParams.set('q', keyword);

    clearFiltration();
    navigate(`${ROUTES.PRODUCTS}?${searchParams.toString()}`, { replace: true });
  };

  const handleClickLogo = () => {
    window.scrollTo({ top: 0 });
    navigate(DASHBOARD);
  };

  const moveToShoppingCart = useCallback(() => {
    if (!(!isBuyerType && isLoggedIn)) {
      navigate(ROUTES.CART, {
        state: {
          buyNow: false,
          refreshThePage: Date.now(),
        },
      });
    }
  }, [isBuyerType, isLoggedIn]);

  return (
    <AppBar
      className="mobile_container_grid_h"
      sx={{
        backgroundColor: '#fcfdfd',
        height: '100px',
        boxShadow: 'none',
      }}
    >
      <AddProductInShoppingCardAlert />
      <CheckDeliveryDatesAlert />
      <CheckShoppingCartAlert />
      <CreateProductReviewAlert />
      <CheckInstallmentLimitAlert />
      <Box
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        className={classNames('header-default mobile_container_grid_h', {
          'header-scroll': headerBoxShadow,
        })}
      >
        <Fb
          justifyContent="center"
          sx={{
            backgroundColor: '#F4F6F9',
            width: '100%',
            height: '44px',
            color: '#828282;',
          }}
        >
          <div className="header-info-wrap">
            <GeolocationMap />
            {/* <>
              <Link
                className="phone-wrap"
                href="tel:88005505728"
                underline="none"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <PhoneIcon width="20px" alt="phone" />
                <Typography className="phone">8 (800) 550 57 28</Typography>
                <div className="popap">
                  <div className="popap-arrow"></div>
                  <ClockIcon />
                  <div className="clock-wrap">
                    <p> Пн-Вс: 09:00 - 21:00 мск</p>
                    <p> Звонок бесплатный </p>
                  </div>
                </div>
              </Link>
            </> */}
            <Link
              href="https://t.me/Maxmarket24store"
              underline="none"
              target="_blank"
              color={'#222'}
              fontSize={16}
              lineHeight={'normal'}
            >
              <Fb alignCenter gap={'10px'}>
                <TelegramIcon />
                <Typography color={'#222'} fontSize={14} lineHeight={'normal'}>
                  по всем вопросам
                </Typography>
              </Fb>
            </Link>
          </div>
        </Fb>

        <Grid
          container
          justifyContent="center"
          sx={{ height: '66px', width: '1180px', maxWidth: '1440px' }}
        >
          <Grid
            item
            xs={12}
            ref={anchorRef}
            sx={{ height: '66px', display: 'flex', justifyContent: 'start' }}
          >
            <Box sx={{ pb: 2 }}></Box>
            <Grid className="forms-wrapper">
              <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
                <MaxMarketHeaderLogo
                  className="cursor-pointer max-market-img"
                  onClick={handleClickLogo}
                />
              </Grid>
              <ErrorBoundary>
                <Catalog anchorRef={anchorRef} />
              </ErrorBoundary>
              <Grid item flex={4}>
                <Form onSubmit={handleSearchProducts}>
                  <FormControl
                    focused={false}
                    fullWidth
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}
                  >
                    <SearchAutocomplete
                      keyword={keyword}
                      isMobile
                      setSearchKeyword={setSearchKeyword}
                    />
                  </FormControl>
                </Form>
              </Grid>

              {isBuyerType && (
                <div className="wishlist-count" onClick={handleRedirectToWishlist}>
                  <Heart />
                  <div className="count">
                    {wishlistProductCount >= 99 ? `99+` : wishlistProductCount}{' '}
                  </div>
                </div>
              )}

              <Grid item className="avatar-wrapper">
                <Fb
                  flexGrow={0}
                  alignItems="center"
                  width="auto"
                  gap="30px"
                  padding="0px"
                  height={44}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={classNames('cart-icon-wrapper', {
                      'cursor-pointer': !(!isBuyerType && isLoggedIn),
                    })}
                    onClick={moveToShoppingCart}
                  >
                    <IconButton
                      disableFocusRipple={true}
                      disabled={!isBuyerType && isLoggedIn}
                      className="cart-icon-wrapper"
                      sx={{
                        p: 0,
                        width: '44px',
                        height: '44px',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                    >
                      <ShoppingCartIcon
                        className={isBuyerType || !isLoggedIn ? '' : 'cart-disabled'}
                        width={24}
                        height={24}
                      />
                    </IconButton>
                    {(isBuyerType || !isLoggedIn) && (
                      <div className="count">{count >= 99 ? `99+` : count} </div>
                    )}
                  </div>
                  <ProfileMenu {...{ buyerAvatar, sellerAvatar, shopLogo }} />
                </Fb>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <Grid
          container
          justifyContent="space-between"
          gap="16px"
          sx={{
            width: '100%',
            color: '#828282;',
          }}
        >
          <Grid
            item
            xs={12}
            px="15px"
            py="4px"
            // height={'30px'}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#F4F6F9"
          >
            <GeolocationMap />
            {!isMobile && (
              <Fb justifyContent="end">
                <Link
                  className="phone-wrap"
                  href="tel:88005505728"
                  underline="none"
                  sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography className="phone">8 (800) 550 57 28</Typography>
                </Link>
              </Fb>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            px="16px"
            height={'auto'}
            sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}
          >
            <Grid className="header_user_icon_wrap">
              <Grid item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <MaxMarketHeaderLogo className="maxmarket_logo" onClick={handleClickLogo} />
              </Grid>

              <Grid item className="avatar-wrapper">
                <Fb
                  flexGrow={0}
                  alignItems="center"
                  width={'auto'}
                  gap={2}
                  padding={0}
                  height={44}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {isBuyerType && (
                    <div className="wishlist-count" onClick={handleRedirectToWishlist}>
                      <Heart />
                      <div className="count">
                        {wishlistProductCount >= 99 ? `99+` : wishlistProductCount}{' '}
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames('cart-icon-wrapper', {
                      'cursor-pointer': !(!isBuyerType && isLoggedIn),
                    })}
                    onClick={moveToShoppingCart}
                  >
                    <IconButton
                      disableFocusRipple={true}
                      disabled={!isBuyerType && isLoggedIn}
                      className="cart-icon-wrapper"
                      sx={{
                        p: 0,
                        width: '16px',
                        height: '16px',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                    >
                      <ShoppingCartIcon
                        className={isBuyerType || !isLoggedIn ? '' : 'cart-disabled'}
                        width={24}
                        height={24}
                      />
                    </IconButton>
                    {(isBuyerType || !isLoggedIn) && (
                      <div className="count">{count >= 99 ? `99+` : count} </div>
                    )}
                  </div>
                  <ProfileMenu {...{ buyerAvatar, sellerAvatar, shopLogo }} />
                </Fb>
              </Grid>
            </Grid>
            <Form onSubmit={handleSearchProducts}>
              <FormControl
                focused={false}
                fullWidth
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}
              >
                <SearchAutocomplete
                  keyword={keyword}
                  isMobile
                  setSearchKeyword={setSearchKeyword}
                />
              </FormControl>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Header;
