import CircularProgress from '@mui/material/CircularProgress';

import Fb from 'components/FB';

interface Props {
  className?: string;
  size?: number;
}

const MaxMarketLoader = ({ className, size = 45 }: Props) => {
  return (
    <Fb
      className={className}
      flex1
      alignCenter
      justifyCenter
      sx={{ width: '100%', height: '100%' }}
    >
      <CircularProgress size={size} sx={{ color: '#C6C6C6' }} />
    </Fb>
  );
};

export default MaxMarketLoader;
