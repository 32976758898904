import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';

import {
  DynamicPagesInterface,
  GetDynamicDataListRequestParams,
  GetDynamicDataRequestParams,
} from './types';

const getDynamicPageList = async ({ page, type, limit }: GetDynamicDataListRequestParams) =>
  await read(`${REACT_APP_BUYER_URL}/dynamic-pages/types/${type}?page=${page}&limit=${limit}`);

export const getDynamicPageListThunk = createAsyncThunk('dynamicPages/list', getDynamicPageList);

const getDynamicPageData = async ({ id }: GetDynamicDataRequestParams) =>
  await read(`${REACT_APP_BUYER_URL}/dynamic-pages/${id}`);

export const getDynamicPageDataThunk = createAsyncThunk('dynamicPage/get', getDynamicPageData);

const initialState: DynamicPagesInterface = {
  data: [],
  dynamicPageData: {
    id: 0,
    content: '',
    createdAt: '',
    listImagePath: '',
    mainImagePath: '',
    name: '',
    title: '',
    type: 1,
  },
  meta: {},
};

const DynamicPagesSlice = createSlice({
  name: 'dynamicPages',
  initialState,
  reducers: {
    resetSinglePage: (state: DynamicPagesInterface) => {
      return {
        ...state,
        dynamicPageData: initialState.dynamicPageData,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getDynamicPageListThunk.fulfilled.type,
        (state: DynamicPagesInterface, action: any) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(
        getDynamicPageDataThunk.fulfilled.type,
        (state: DynamicPagesInterface, action: any) => {
          return {
            ...state,
            dynamicPageData: { ...action.payload },
          };
        }
      );
  },
});

export const { resetSinglePage } = DynamicPagesSlice.actions;
export default DynamicPagesSlice.reducer;
