import { FC, Fragment, memo } from 'react';

import { Typography, Grid } from '@mui/material';

import Fb from 'components/FB';

import { Banner } from 'store/banners/types';
import { Category } from 'store/catalog/types';
import SubCategoryItems from './SubCategoryItems/SubCategoryItems';

import subCategoryDataGroups from './constants';

interface Props {
  subCategory: Category;
  banner: Banner;
  subRef: any;
  handleCloseCatalogMenu: () => void;
}

const SubCategories: FC<Props> = ({ subCategory, banner, subRef }) => {
  const subCategoryData = subCategory?.subCategories?.data;
  const subGroupCategoryData = subCategoryDataGroups(subCategoryData);

  return (
    <>
      <Grid item className="catalog__sub">
        <Typography className="title">{subCategory?.name}</Typography>
        <div className="sub__wrapper" ref={subRef}>
          {subGroupCategoryData?.map((group, index) => (
            <ul key={index} className="sub__list">
              {group
                ?.filter(e => e.isActive)
                .map(subCategoryItem => (
                  <Fragment key={subCategoryItem.id}>
                    <SubCategoryItems subCategory={subCategoryItem} />
                  </Fragment>
                ))}
            </ul>
          ))}
        </div>
      </Grid>

      <Grid item>
        <Fb className="catalog__banner_wrapper">
          {!!banner && (
            <img
              src={banner.path}
              onClick={() => window.open(banner.url || '/', '_blank')}
              alt="category logo"
              className="catalog__img cursor-pointer"
            />
          )}
        </Fb>
      </Grid>
    </>
  );
};

export default memo(SubCategories);
