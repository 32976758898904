import ROUTES from 'constants/routes';

import { getAccountTabRoute } from 'helpers/routeHelper';
import accountTabs from 'constants/accountTabs';

import { ReactComponent as homeSvg } from 'assets/icons/home.svg';
import { ReactComponent as ShoppingCartSvg } from 'assets/icons/shoppingCartFooter.svg';
import { ReactComponent as UserSvg } from 'assets/icons/menuUserFooter.svg';
import { ReactComponent as CatalogSvg } from 'assets/icons/catalog.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heartnavbar.svg';

const { PERSONAL_INFO } = accountTabs;

export const accountPersonalInfoRoute = getAccountTabRoute(PERSONAL_INFO);

const { DASHBOARD, MOBILECATALOG, CART, WISHLIST } = ROUTES;

export const items = [
  {
    path: DASHBOARD,
    Icon: homeSvg,
    name: 'Главная',
  },
  {
    path: MOBILECATALOG,
    Icon: CatalogSvg,
    name: 'Каталог',
  },
  {
    path: CART,
    Icon: ShoppingCartSvg,
    name: 'Корзина',
  },
  {
    path: WISHLIST,
    Icon: HeartIcon,
    name: 'Избранное',
  },
  {
    path: accountPersonalInfoRoute,
    Icon: UserSvg,
    name: 'Профиль',
  },
];
